<template>
    <v-container>
        <v-toolbar 
		class="toolbar_text"
		id="dialog_toolbar"
        dark
        color="#E7D8AC"
		height="80"
        >
		   <v-icon
           large
           color="#ffffff"
           >
           mdi-import
           </v-icon>
              <v-toolbar-title class="toolbar_text">New Image</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                 <v-btn
                 color="#695E4A"
                 text
                 @click="$router.back()"
                 >
                 Close
                 </v-btn>
              </v-toolbar-items>
        </v-toolbar> 
        <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        > 
           <v-row>
                <v-col
                lg="6"
                md="12"
                sm="12"
		        cols="12"
                >  
                   <v-row class="ml-2">
                       <v-col
                       cols="12"
                       sm="12"
                       >
                          <v-text-field 
                           @input="form_changed"
                           v-model="asset.asset_title"
                           :rules="asset_title_rules"
                           counter="75"
                           hint="A title for this image"
                           label="Type a title for this image"
                           >
                         </v-text-field>
                     </v-col>
                     <v-col
                     cols="12"
                     sm="12"
                     >
                        <v-textarea
						 :rows= "description_field_rows"
                         label="Type an image description"
                         v-model="asset.asset_description"
                         hint="provide brief description about this image. e.g. what kind of deterioration does it displays"
                         name="input-7-1"
                         >
                        </v-textarea>
                     </v-col>
				         <v-col
                     cols="12"
                     sm="12"
                     >
                         <v-combobox
						 @change="clear_tag_search()"
                         v-model="asset_tags"
                         :items="pluggy_tags"
                         :search-input.sync="tag_search"
                         hide-selected 
                         hint="Start typing to view already used tags derived from Pluggy platform"
                         label="Add image tags"
                         multiple
                         persistent-hint
                         small-chips
                         >
                             <template v-if="tag_search" v-slot:no-data>
                                 <v-list-item>
                                     <v-list-item-content>
                                         <v-list-item-title>
                                          No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                         </v-list-item-title>
                                     </v-list-item-content>
                                 </v-list-item>
                             </template>
                         </v-combobox>
                     </v-col>
                     <v-col
                     cols="12"
                     sm="12"
                     >
                           <v-file-input @change="file_changed()" v-model="asset.image_file" :rules="imageRules"  prepend-icon="mdi-camera" chips show-size accept="image/*" label="Image"></v-file-input>
                     </v-col>
                </v-row>  
         </v-col>
         <v-col
         lg="6"
         md="12"
         sm="12"
		 cols="12"
         >
            <v-alert
            class="mt-lg-4"
            color="#E7D8AC"
            dark
            icon="mdi-pin"
            dense
            >
                <div class="map_alert">
                 Pick a point on the map below so as to provide location for this image 
                </div>
            </v-alert>
            <v-container
            v-if="asset_longitude && asset_latitude"
            class="grey lighten-5  mt-0 pt-0"
            >
               <v-row
               align="center"
               no-gutters
               >
                  <v-col>
                       <v-text-field    
					   @input="form_changed"
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="asset_longitude"
                       label="Longitude"
                       outlined
                       readonly
                       >
                       </v-text-field>
                  </v-col>
                  <v-col>
                       <v-text-field   
					   @input="form_changed" 
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="asset_latitude"
                       label="Latitude"
                       outlined
                       readonly
                       >
                       </v-text-field>
                  </v-col>
                  <v-col
				  lg="12"
                  md="4"
                  sm="12"
		          cols="12"
				  > 
                       <v-btn
                       @click="clear_asset_location()"
                       class="mb-md-7 mx-xs-0 mx-md-4"
                       x-large
                       outlined
                       color="#695E4A"
                       >
                           <v-icon>mdi-eraser</v-icon>
                           Clear Location
                           </v-btn>
                  </v-col>
               </v-row>       
            </v-container>
            <div id="map" class="crosshair_cursor">
            </div>
			<v-btn @click="store_asset_metadata()"  v-if="is_the_owner_of_exhibition" class="ml-4 mt-4" depressed :disabled="!form_validated">
			<v-icon left>
            mdi-content-save
            </v-icon>
            Upload Photo
        </v-btn> 
         </v-col>
      </v-row>    
     </v-form>   
     

	  <v-overlay light :value="overlay" z-index=1000 opacity="0.1">
          <v-progress-circular
          indeterminate
          color="primary"
          >
	      </v-progress-circular>
      </v-overlay>
	 
	  <v-row justify="center">
    	 <v-dialog
         v-model="storage_success_dialog"
         persistent
         max-width="400"
         >
            <v-card>
               <v-card-title class="headline">
               Successful Image Storage!
               </v-card-title>
               <v-card-text>The image has been successfully uploaded.</v-card-text>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="green darken-1"
                  text
                  @click="$router.back()"
                  >
		             <v-icon left>
                     mdi-home
                     </v-icon>
                  Home
                  </v-btn>
				 
               </v-card-actions>
            </v-card>
         </v-dialog>
      </v-row>

	  <v-snackbar
	  timeout="5000"
      v-model="snackbar"
      >
      The location of the image has been derived automatically
    </v-snackbar>



 
    
 
</v-container>   
</template>


<script>
import jwt_decode from "jwt-decode";
import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL } from '../constants'; 
import axios from 'axios';
import EXIF from 'exif-js';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import store from '../store'
import { componentBus } from '../main';


export default {
  name: 'PhotoAddition',
  components: {
  
  },

  
//data: function () {
//return {
data: () => ({	
snackbar: false,
exhibition_is_public: null,
asset_longitude: null,
asset_latitude: null,
form_validated: false,
storage_success_dialog: false,
exhibition_point_assets_list: [],
exhibition_owner_id: null,
asset: {},
exhibition_id: null,
exhibition_point_id: null,
overlay: false,
map: null,
marker: null,  
pluggy_tags: [],
tag_search: null,
//asset_tags: null,
asset_tags: [],
valid: true,

asset_title_rules: [
        v => (v && v.length > 0)  || 'Image title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',     
],

imageRules: [
        v => (!!v ) || 'Image is required',   
],

custom_marker: L.Marker.extend({
    options: { 
              asset_id: '',
              asset_title: ''
              }
    }),
citizen_story_pin_icon : L.icon({
    iconUrl: "../../../../monument.svg",
    iconSize: [30, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -60]
	}),



    

}),
//}},
  //}),
  mounted () {

  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });
 
  this.reset_validation();
  this.logged_in_user_id= jwt_decode(store.state.oidcStore.access_token.toString()).userId;

  
	this.map = L.map('map', { zoomControl: false} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
    if(this.marker!=null) this.map.removeLayer(this.marker);  
	let icon=this.citizen_story_pin_icon;
    let m=  new this.custom_marker([e.latlng.lat,e.latlng.lng], {icon: icon, asset_id: this.asset.asset_id, asset_title: this.asset.asset_title }).on('click', (e) => {
	});
	this.map.addLayer( m ); 
	this.marker=m;  
    console.log(e);  
	this.asset_longitude= e.latlng.lng;
	this.asset_latitude= e.latlng.lat;
  });

 L.control.zoom({
  position:'bottomright'
  }).addTo(this.map);

  L.tileLayer(MAP_URL, {
  attribution: ATTRIBUTION,
  subdomains: ['a', 'b', 'c']
  })
  .addTo(this.map);

	 

  

 

  this.exhibition_id= this.$route.params.exhibition_id;
  this.exhibition_point_id= this.$route.params.exhibition_point_id;
 
  this.fetch_info();
  },
  

  methods: {

 

file_changed() {

let _this=this;
if (this.asset.image_file!=null)  
EXIF.getData(this.asset.image_file, function() {
      
		let longitude_pre_processed= EXIF.getTag(this, "GPSLongitude");
		let lonDirection= EXIF.getTag(this, "GPSLongitudeRef");
		let latitude_pre_processed= EXIF.getTag(this, "GPSLatitude");
		let latDirection= EXIF.getTag(this, "GPSLatitudeRef");
		if(longitude_pre_processed || lonDirection || latitude_pre_processed || latDirection)
		{
		let lonDegree=  longitude_pre_processed[0];
		let lonMinute= longitude_pre_processed[1];
		let lonSecond= longitude_pre_processed[2];
		
		
		var dd = lonDegree + (lonMinute/60) + (lonSecond/3600);
        if (lonDirection == "S" || lonDirection == "W") {
        dd = dd * -1;
        }
        _this.asset_longitude= dd;

	
		let latDegree=  latitude_pre_processed[0];
		let latMinute= latitude_pre_processed[1];
		let latSecond= latitude_pre_processed[2];
		
		
		dd = latDegree + (latMinute/60) + (latSecond/3600);
        if (latDirection == "S" || latDirection == "W") {
        dd = dd * -1;
        }
        _this.asset_latitude= dd;     
		
		if(_this.marker!=null) _this.map.removeLayer(_this.marker);  
	    let icon=_this.citizen_story_pin_icon;
        let m=  new _this.custom_marker([_this.asset_latitude,_this.asset_longitude], {icon: icon, asset_id: "id", asset_title: "asset_title" }).on('click', (e) => {
	});
	   _this.map.addLayer( m ); 
	   _this.marker=m;  
	   _this.snackbar=true;
	   _this.form_changed();
	}
    });
	else this.form_changed();
	
},

search_pluggy_tags(text) {
let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
axios.get(pluggy_tags_url_encoded
).then(response => {
   if (response.status == "200" || response.status == "201" ) {
	  console.log("pluggy_tags: "+response.data.data);
      this.pluggy_tags=response.data.data;
   }
   else {
         console.log("...");
   }                 
})
.catch(error => {
console.log(error);
});

},

store_image_file_and_update_exhibition_point() {
Promise.all([this.store_image_file(),this.update_exhibition_point()])
.then( (results) => {
this.overlay= false;
this.storage_success_dialog= true;
});
},

store_image_file() {

let upload_image_url=APIS_BASE_URL+"/assets/"+this.asset.asset_database_id+"/media";  
let upload_image_url_encoded= encodeURI(upload_image_url);
let formData = new FormData();
formData.append('file',this.asset.image_file);

return axios.post( upload_image_url_encoded,
          formData,
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (image_upload_response) =>{
                    if (image_upload_response.status == "200" )
                     {
					 console.log("image successfully uploaded!");
                     }
                    else
                     {
                     console.log('FAILURE with image upload upload');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with image upload!!');
                     });

},

update_exhibition_point() {

let update_exhibition_point_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+'/exhibition-points/'+this.exhibition_point_id;
let update_exhibition_point_url_encoded= encodeURI(update_exhibition_point_url);
var payload = {};
let already_stored_assets_ids = [];
this.exhibition_point_assets_list.forEach(function (item, index) {
  already_stored_assets_ids.push(item.id);
});
already_stored_assets_ids.push(this.asset.asset_database_id);

payload.assets= already_stored_assets_ids;

return axios.put(update_exhibition_point_url_encoded, JSON.stringify( payload),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition point has been updated with new asset");     
          }
          else {
            console.log("problem with exhibition point assets update ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });
},

store_asset_metadata() {
this.overlay= true;
let asset_metadata_storage_url=APIS_BASE_URL+"/assets";
let asset_metadata_storage_url_encoded= encodeURI(asset_metadata_storage_url);
let payload = {};
payload["public"]= this.exhibition_is_public;
payload["title"]= this.asset.asset_title;
payload["description"]= this.asset.asset_description;
payload["tags"]= this.asset_tags;
payload["location"] =  {  geo: {  coordinates: [this.asset_latitude, this.asset_longitude], type: "Point", zoom: 14        }    };
payload["type"]="image";

return axios.post(asset_metadata_storage_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("asset metadata has been added for new image");
              this.asset.asset_database_id=response.data.data.id;
              console.log("id of newly created asset: "+ this.asset.asset_database_id);
			  this.store_image_file_and_update_exhibition_point();
          }
          else {
            console.log("asset metadata creation problem");
          }                 
          })
          .catch(error => {
          console.log(error);
          });
},

  fetch_info() {
  let config = {
  headers: {
  'Authorization': 'Bearer ' +store.state.oidcStore.access_token 
  },
  }	

  let fetch_exhibition_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id;
  let fetch_exhibition_url_encoded= encodeURI(fetch_exhibition_url);
  axios.get(fetch_exhibition_url_encoded,config)
  .then(response => {
  this.exhibition_owner_id= response.data.data.owner.id; 
  this.exhibition_point_assets_list = response.data.data.exhibitionPoints[0].assets;
  this.exhibition_is_public= response.data.data.public;
		
 });
 },	  

validate_form () {
return this.$refs.form.validate();
},
      
reset_form () {
this.$refs.form.reset();
},
      
reset_validation () {
this.$refs.form.resetValidation();
},

go_previous_page(){
this.$router.back();
},

clear_asset_location()
{
this.asset_longitude=null;
this.asset_latitude=null;
if(this.marker!= null)  this.map.removeLayer(this.marker);
this.marker=null;
// disable save button...
//this.$emit('can-continue', {value: false});


},

  clear_tag_search()
{ 
this.tag_search=null;
},

  form_changed() {
  let valid_locations=true;
  
  if (this.asset_longitude == null ||  this.asset_latitude == null) valid_locations=false;
  
	  
  if( this.validate_form() && valid_locations ) this.form_validated=true;
  else this.form_validated= false;
  
	  

},
	
 
               
  },
  beforeDestroy() {
    console.log("pame gia katasrofi");
    
  },

  watch: {
    
  asset_longitude (val) {
  this.form_changed();
  console.log("longitude changed");
  },
  asset_latitude(val) {
  this.form_changed();	  
  console.log("latitude changed");
  },

  tag_search (val) {
  if( val!="" )
    this.search_pluggy_tags(val);
  else this.pluggy_tags=[];
},
   
	
    
   },

   computed: {
    // a computed getter
    is_the_owner_of_exhibition: function () {
      // `this` points to the vm instance
      return this.logged_in_user_id== this.exhibition_owner_id;
	},

	description_field_rows () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 2
          case 'sm': return 2
          case 'md': return 4
          case 'lg': return 6
          case 'xl': return 8
        }
      },
	
	
},
    
     
  
};
</script>

<style>



#map {
width: 100%;
height: 330px;
z-index: 1;
}

.map_alert {
  color:rgba(0, 0, 0, 0.87);;
}

.toolbar_text {
  color:rgba(0, 0, 0, 0.87);;
}

.crosshair_cursor {
  cursor: crosshair !important;
}

</style>
