import { vuexOidcCreateStoreModule } from 'vuex-oidc'

import { oidcSettings } from './config/oidc'
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
	//oidcStore: vuexOidcCreateStoreModule(oidcSettings, {namespaced: true, dispatchEventsOnWindow: true, isAuthenticatedBy: 'access_token'}            )
  oidcStore: vuexOidcCreateStoreModule(oidcSettings, {namespaced: true, dispatchEventsOnWindow: true, isAuthenticatedBy: 'access_token'   },
  { accessTokenExpired: () => console.log('Access token did expire'), userLoaded: (user) => console.log('OIDC user is loaded:', user),accessTokenExpiring: () => console.log('Access token will expire'),
  automaticSilentRenewError: (payload) => console.log('Automatic silent renew failed.', payload.error),  silentRenewError: () => console.log('OIDC user is unloaded'),}
  
  // ,{
    //userLoaded: (user) => console.log('OIDC user is loaded:', user),
   
  //}       
     )
  }
})