//﻿export const API = 'https://147.102.5.93:8443/SensorThing';  
export const APIS_BASE_URL = 'https://pluggy.eu/api/v1';
//export const API = 'https://toronto-bike-snapshot.sensorup.com';
export const ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://cartodb.com/attributions">CartoDB</a>';
 

export const MAP_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';
export const MARKER_COLOR = '#695E4A';
export const MARKER_HTML_STYLE = `
    background-color: ${MARKER_COLOR};
    width: 2rem;
    height: 2rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`;

export const STATIC_BASE_URL=    process.env.NODE_ENV === 'production' ?  'https://hyperion.iccs.gr'  : 'http://localhost:8080';
