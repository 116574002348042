<template>
  <v-app>
    <v-navigation-drawer 
       bottom
       width="320"
       id="left_menu"
       color="#E8E5D7"
       v-model="drawer"
       app
       :mini-variant.sync="mini"
       >
        <v-treeview
        activatable
        shaped
        hoverable
        transition
        @update:active="left_menu_item_selected"
      
        :items="left_menu_items"
        open-on-click
     
        :return-object="true">
          <template v-slot:prepend="{ item } "  >
            <v-icon
             v-if="item.id===1"
             v-text="`mdi-home-variant`">
            </v-icon>
            <v-icon
              v-else-if="item.id===2 || item.id===3"
              v-text="`mdi-plus-circle`">
            </v-icon>
            <v-icon
              v-else-if="item.id===4"
              v-text="`mdi-search-web`">
            </v-icon>
            <v-icon
            v-else-if="item.id===7"
            v-text="`mdi-link-variant`">
            </v-icon>


          </template>
        </v-treeview>
      </v-navigation-drawer>

	<v-navigation-drawer
	id="left_menu_mobile"
    v-model="mobile_drawer"
    absolute
    temporary
	color="#E8E5D7"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="mobile_left_menu_action('home')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

		  <v-list-item @click="mobile_left_menu_action('logout')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>

		  <v-divider></v-divider>

          <v-list-item @click="mobile_left_menu_action('citizen_story_creation')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-plus-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Create Hyperion Citizen Story</v-list-item-title>
          </v-list-item>

		   <v-list-item @click="mobile_left_menu_action('business_owners_story_creation')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-plus-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Create Hyperion Business Story</v-list-item-title>
          </v-list-item>
       <!--
		   <v-list-item @click="mobile_left_menu_action('search_for_citizen_story')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-plus-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Search for Citizen Stories</v-list-item-title>
          </v-list-item>

		  <v-list-item @click="mobile_left_menu_action('search_for_business_story')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-plus-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Search for Business Stories</v-list-item-title>
          </v-list-item>
-->
		   <v-list-item @click="mobile_left_menu_action('visit_pluggy')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Visit Pluggy_Platform</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>





    <v-app-bar v-if="bar_shown" id="appbar"
     fixed
     color="#9AD8DA"
     dark
     height="80"
    > 
	  <v-app-bar-nav-icon @click="mobile_drawer=true" v-if="$vuetify.breakpoint.mobile"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
		<!--
        <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        :src="static_base_url+'/Platform_logo.svg'"
        transition="scale-transition"
        width="40"
        height="50"
        />  -->
		<v-img
        alt="Commmission"
        class="shrink mr-2"
        contain
        src="https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg"
        transition="scale-transition"
        width="40"
        />  
        <div v-if="!$vuetify.breakpoint.mobile" class="title font-weight-bold" style="color: #695E4A;">
         Hyperion Engagement Tool
        </div>  
      </div>
      <v-spacer></v-spacer> 
      <v-img
      alt="Project and Organisation Logo"
      class="pa-2"
      contain
      :src="static_base_url+'/all_logos.svg'"
      transition="scale-transition"
      width="200"
      height="50"
      />  
      <div v-if="oidcIsAuthenticated && !$vuetify.breakpoint.mobile" class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="title font-weight-bold" style="color: #695E4A;"
            v-bind="attrs"
            v-on="on">
            Welcome {{oidcAccessToken | extract_username}} 
            </div>
          </template>
          <v-list>
            <v-list-item
             v-for="(item, index) in items"
             :key="index"
             @click="logout">
             <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>  
    </v-app-bar>

 
    

    <v-main id="main">
      <v-container id="container" fluid>
      <router-view :key="$route.path"></router-view>
       </v-container>
    </v-main>

<v-card v-if="search_visible"
      class="d-flex justify-center"
      color=""
      flat
      tile
    >
  <div id="search_element" class="leaflet-control">


   <v-toolbar
    dark
    
  >
    <v-toolbar-title>Citizen Story Selection</v-toolbar-title>
    <v-autocomplete
      return-object
      v-on:change="citizen_story_selected"
      full-width
      v-model="select"
      :loading="loading"
      :items="my_items"
      :search-input.sync="search"
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Start typing the name of the Citizen Story you want to view.."
      solo-inverted
    ></v-autocomplete>
  
  </v-toolbar>

</div>
</v-card>



<v-card v-if="business_search_visible"
      class="d-flex justify-center"
      color=""
      flat
      tile
    >
  <div id="business_search_element" class="leaflet-control">


   <v-toolbar
    dark
    
  >
    <v-toolbar-title>Business Story Selection</v-toolbar-title>
    <v-autocomplete
      return-object
      v-on:change="business_story_selected"
      full-width
      v-model="business_select"
      :loading="business_loading"
      :items="business_items"
      :search-input.sync="business_search"
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Start typing the name of the Business Story you want to view.."
      solo-inverted
    ></v-autocomplete>
  
  </v-toolbar>

</div>
</v-card>



  </v-app> 
</template>

<script>
import router from './router';
import store from './store'
import { mapGetters, mapActions } from 'vuex'
import HelloWorld from './components/HelloWorld.vue';
import { componentBus } from './main';

import jwt_decode from "jwt-decode";
import axios from 'axios';
import {  APIS_BASE_URL } from './constants';

import {  STATIC_BASE_URL } from './constants';

export default {
  
  filters: {
  extract_username: function (value) {
    if (!value) return ''
    value = value.toString()
    return jwt_decode(value).username;
  }
},
  name: 'App',
  mounted() {
      
   
    
   
  },
  watch:{
    $route (to, from){
		console.log(to);
		console.log(to.name);
		if(to.name=="full_image_view") this.bar_shown=false;
		  else this.bar_shown=true;
    },

	search (val) {
	val && val !== this.select && this.querySelections(val)
	if(!val) this.get_all_citizen_stories();
    },
  
    business_search (val) {
	val && val !== this.business_select && this.business_querySelections(val)
	if(!val) this.get_all_business_stories();
    },

}, 
  components: {
    HelloWorld,
  },
  computed: {
          ...mapGetters('oidcStore',[
      'oidcIsAuthenticated','oidcUser','oidcAccessToken'
    ])
    

  

  },


  data: () => ({

    search_visible: false,
     loading: false,
    my_items: [],
    search: null,
    select: null,

	business_search_visible: false,
	business_loading:false,
	business_items: [],
	business_search: null,
	business_select: null,

	 mobile_drawer: false,
     group: null,
	 static_base_url: STATIC_BASE_URL,
	 bar_shown: true,
     items: [
        { title: 'Logout' },
       
      ],
      username: null,
      drawer: true,
      mini: false,

      left_menu_items: [
         {

          id: 1,
          name: 'Home'
        
        },
        {
          id: 2,
          name: 'Create Hyperion Citizen Story',
          },
          {
          id: 3,
          name: 'Create Hyperion Business Story',
          }, 
          {
          id: 4,
          name: 'Searh for Hyperion Stories',
		  children: [
            {
              id: 5,
              name: 'Search for Citizen Stories',
              
            },
            {
              id: 6,
              name: 'Search for Business Stories',
             
            },


            
          ]

          }, 
           {
          id: 7,
          name: 'Visit Pluggy Platform',
          }, 
        ]

      

    //
  }),
    methods: {

	mobile_left_menu_action(action){
    switch(action) {
         case "home":
            if(router.currentRoute.path!="/")
			{
			  this.reset_citizen_story_search();
              router.push( {path: '/'});
			}
            break;
		 case "citizen_story_creation":
            if(router.currentRoute.path!="/citizen_story_creation")
			{
			  this.reset_citizen_story_search();
              router.push(  {path: '/citizen_story_creation'} );
			}
            break;
         case "business_owners_story_creation":
			if(router.currentRoute.path!="/business_owners_story_creation")
			{
			  this.reset_citizen_story_search();
              router.push(  {path: '/business_owners_story_creation'} );
			}
            break;
         case "search_for_citizen_story":
			this.search_visible=true;
			this.get_all_citizen_stories();
            break;
         case "visit_pluggy":
            window.open("https://pluggy.eu", '_blank');
            break;
		 case "logout":
			this.reset_citizen_story_search();
            this.logout();
            break;	
         default:
            alert("dothike default");
    
} 
	},
    
    ...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'oidcSignInCallback', // Handles callback from authentication redirect. Has an optional url parameter
      'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
      'getOidcUser', // Update user in store
      'signOutOidc', // Signs out user in open id provider
      'removeUser', // Signs out user in vuex and browser storage, but not in open id provider
      'removeOidcUser',
      'signOutOidcSilent'
    ]),
    userLoaded: function () {
      console.log('I am listening to the user loaded event in vuex-oidc')
    },
    logout () {
      this.removeOidcUser();
      this.authenticateOidc();
/*
      let token= store.state.oidcStore.access_token;
      console.log(store.state.oidcStore.access_token);
      let get_assets=APIS_BASE_URL+"/assets/my";
      let get_assets_url_encoded= encodeURI(get_assets);

      axios.get(get_assets_url_encoded, {
  headers: {
     'Authorization': `Bearer ${token}`
  }
})
        .then(response => {
           
        response.data.data.result.forEach(function (item, index) {
        console.log(item["_id"], index);
        });
        }).catch(error => console.log(error));


*/

    },


    left_menu_item_selected(evt){

    if (typeof evt[0] != 'undefined'  ) {
       
       switch(evt[0].id) {
         case 1:
            if(router.currentRoute.path!="/")
			{
			  this.reset_business_story_search();
			  this.reset_citizen_story_search();
              router.push( {path: '/'});
			}
            break;
         case 2:
            if(router.currentRoute.path!="/citizen_story_creation")
			{
			  this.reset_citizen_story_search();
			  this.reset_business_story_search();
              router.push(  {path: '/citizen_story_creation'} );
			}
            break;
         case 3:
			if(router.currentRoute.path!="/business_owners_story_creation")
			 {
			  this.reset_citizen_story_search();
			  this.reset_business_story_search();
              router.push(  {path: '/business_owners_story_creation'} );
			 }
            break;
		 case 4:
            break;
		 case 5:
			if(router.currentRoute.path!="/search_for_citizen_story")
			 {
			  this.reset_business_story_search();
              this.search_visible=true;
			  this.get_all_citizen_stories();
			 }
            break; 
		 case 6:
			if(router.currentRoute.path!="/search_for_business_story")
			 {
			  this.reset_citizen_story_search();
              this.business_search_visible=true;
			  this.get_all_business_stories();
			 }
            break; 
         case 7:
            window.open("https://pluggy.eu", '_blank');
            break;
         default:
            alert("dothike default");
    
} 

    }
    },


    querySelections (v) {
    this.my_items.splice(0,this.my_items.length);
    var citizen_story_search_url=APIS_BASE_URL+"/search/?q="+v;
    var citizen_story_search_url_encoded= encodeURI(citizen_story_search_url);
    this.loading = true
   
    axios.get(citizen_story_search_url_encoded)
    .then(response => {
    response.data.data.result.forEach(val => {
	if(val.type=="hyperion_citizen_story") {
    let object = {
        text: val.title,
        value: val["_id"]
    };
	console.log(object);
    this.my_items.push(object);
	console.log(this.my_items);
    
	}              
    });
    this.loading=false;            
    })
    .catch(error => console.log(error)); 
       
          
    },

    business_querySelections (v) {
    this.business_items.splice(0,this.business_items.length);
    var business_story_search_url=APIS_BASE_URL+"/search/?q="+v;
    var business_story_search_url_encoded= encodeURI(business_story_search_url);
    this.business_loading = true
   
    axios.get(business_story_search_url_encoded)
    .then(response => {
    response.data.data.result.forEach(val => {
	if(val.type=="hyperion_business_owners_story") {
    let object = {
        text: val.title,
        value: val["_id"]
    };
	console.log(object);
    this.business_items.push(object);
	console.log(this.business_items);
    
	}              
    });
    this.business_loading=false;            
    })
    .catch(error => console.log(error)); 
       
          
    },

	citizen_story_selected: function (event) {
    // `this` inside methods points to the Vue instance
    //alert("malaka");
    console.log("value: "+event.value);
    console.log("text: "+ event.text);
    //let payload= {};
    //payload["point_uuid"]= event.value;
    //payload["relevant_project"]=event.relevant_project;
    //componentBus.$emit('render_relevant_pois_and_range_and_focus_on_map', payload);

    if(router.currentRoute.path!="/citizen_story_view/"+event.value)
	{ 
	this.reset_citizen_story_search();
    router.push({ path: '/citizen_story_view/'+event.value });
	}
	else this.reset_citizen_story_search();
	
    // `event` is the native DOM event
     
    },

	business_story_selected: function (event) {
    // `this` inside methods points to the Vue instance
    //alert("malaka");
    console.log("value: "+event.value);
    console.log("text: "+ event.text);
    //let payload= {};
    //payload["point_uuid"]= event.value;
    //payload["relevant_project"]=event.relevant_project;
    //componentBus.$emit('render_relevant_pois_and_range_and_focus_on_map', payload);

    if(router.currentRoute.path!="/business_story_view/"+event.value)
	{ 
	this.reset_business_story_search();
    router.push({ path: '/business_story_view/'+event.value });
	}
	else this.reset_business_story_search();
	
    // `event` is the native DOM event
     
    },

	get_all_citizen_stories() 
	{
    var all_citizen_stories_search_url=APIS_BASE_URL+"/exhibitions/?kind=ContentExhibition&limit=1000&type=hyperion_citizen_story";
    var all_citizen_stories_search_url_encoded= encodeURI(all_citizen_stories_search_url);
    this.loading = true
    this.my_items.splice(0,this.my_items.length);
    axios.get(all_citizen_stories_search_url_encoded)
    .then(response => {
    response.data.data.result.forEach(val => {
    let object = {
    text: val.title,
    value: val["_id"],
    };
    this.my_items.push(object);
    
                   
    });
    this.loading=false;              
    })
    .catch(error => console.log(error)); 
	},

	get_all_business_stories() 
	{
    var all_business_stories_search_url=APIS_BASE_URL+"/exhibitions/?kind=ContentExhibition&limit=1000&type=hyperion_business_owners_story";
    var all_business_stories_search_url_encoded= encodeURI(all_business_stories_search_url);
    this.business_loading = true
    this.business_items.splice(0,this.business_items.length);
    axios.get(all_business_stories_search_url_encoded)
    .then(response => {
    response.data.data.result.forEach(val => {
    let object = {
    text: val.title,
    value: val["_id"],
    };
    this.business_items.push(object);
    
                   
    });
    this.business_loading=false;              
    })
    .catch(error => console.log(error)); 
	},

	reset_citizen_story_search ()
	{
    this.loading= false,
    //this.my_items= [];
	this.my_items.splice(0,this.my_items.length);
    this.search= null;
    this.select= null,
	this.search_visible=false;
	},

	reset_business_story_search ()
	{
    this.business_loading= false,
    //this.my_items= [];
	this.business_items.splice(0,this.business_items.length);
    this.business_search= null;
    this.business_select= null,
	this.business_search_visible=false;
	},




    }

   
  
};
</script>

<style>

#search_element {
 position: fixed;
 bottom: 0;
 margin-bottom: 50px;
 width: 850px;


}

#business_search_element {
 position: fixed;
 bottom: 0;
 margin-bottom: 50px;
 width: 850px;


}

#left_menu {

   padding-top: 90px;
   margin-right: 0;
   padding-right: 0;

  
} 

#left_menu_mobile {
 z-index: 1000;
 padding-top: 80px;
} 


#appbar {
  z-index: 1000;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

#container {
  margin-top: 100px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
#main {
 
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
</style>