import Vue from 'vue';
import Router from 'vue-router';
import OidcCallback from './components/OidcCallback.vue';
import {vuexOidcCreateRouterMiddleware} from 'vuex-oidc';
import HelloWorld from './components/HelloWorld.vue';
import Test from './components/Test.vue';
import InteractiveMap from './components/InteractiveMap.vue';
import CitizenStoryCreation from './components/CitizenStoryCreation.vue';
import BusinessOwnersStoryCreation from './components/BusinessOwnersStoryCreation.vue';
import CitizenStoryView from './components/CitizenStoryView.vue';
import FullImageView from './components/FullImageView.vue';
import BusinessStoryView from'./components/BusinessStoryView.vue';
import PhotoAddition from'./components/PhotoAddition.vue';
import SearchCitizenStory from './components/SearchCitizenStory.vue';
import { mapActions, mapGetters } from 'vuex'  

import store from '@/store'

Vue.use(Router);
 var computed ={
	...mapGetters('oidcStore',[
'oidcIsAuthenticated','oidcUser','oidcAccessToken'
])



}
const router= new Router( {
	routes: [

		{
			path: '/aekara',  
			component: Test,
			   name: 'test',
			   meta: {
				isPublic: false
			  }
		
		},
		{
			path: '/citizen_story_creation',  
			component: CitizenStoryCreation,
			   name: 'citizen_story_creation',
			   meta: {
				isPublic: false
			  }
		
		},
		{
			path: '/business_owners_story_creation',  
			component: BusinessOwnersStoryCreation,
			   name: 'business_owners_story_creation',
			   meta: {
				isPublic: false
			  }
		
		},
		{
			path: '/oidc_callback',  
			component: OidcCallback,
			   name: 'OidcCallback',
			   
		
		},

		{
            path: '/',
            component: InteractiveMap,
			name: 'map',
			meta: {
				isPublic: false
			  }
        
		},
		{
			path: '/vuetify',  
			component: HelloWorld,
			   name: 'helloworld',
			   meta: {
				isPublic: false
			  }
		
		},
		{
			path: '/citizen_story_view/:id',  
			component: CitizenStoryView,
			   name: 'citizen_story_view',
			   meta: {
				isPublic: false
			  }
		
		},
		{
			path: '/business_story_view/:id',  
			component: BusinessStoryView,
			   name: 'business_story_view',
			   meta: {
				isPublic: false
			  }
		
		},
		{  
			path: '/full_image_view/asset/:asset_id/media/:media_id',  
			component: FullImageView,
			   name: 'full_image_view',
			   meta: {
				isPublic: false
			  }
		
		},

		{  
			path: '/photo_addition/exhibition/:exhibition_id/exhibition_point_id/:exhibition_point_id',  
			component: PhotoAddition,
			   name: 'photo_addition',
			   meta: {
				isPublic: false
			  }
		
		},

		{  
			path: '/search_for_citizen_story',  
			component: SearchCitizenStory,
			   name: 'search_citizen_story',
			   meta: {
				isPublic: false
			  }
		
		},
		
		
		
		

	],
	mode: 'history'
})
router.beforeEach(vuexOidcCreateRouterMiddleware(store,'oidcStore'),console.log("before_each: "+store.state.oidcStore.access_token));
export default  router 

