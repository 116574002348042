<template>
  <v-container>
    <v-row   justify="center"> 
	
    <v-dialog 
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      
      <v-card>
		  
        <v-toolbar
		  id="dialog_toolbar"
          dark
          color="#9AD8DA"
		  height="80"
        >
          <v-btn
            icon
            color="#695E4A"
            @click="go_previous_page"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title style="color: #695E4A;">Image Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="#695E4A"
              text
              @click="go_previous_page()"
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar> 
	<!--	<v-card
      	class="d-flex justify-start"
      	:color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      	flat
     	 tile
    		>  -->
        <v-form
       ref="form"
       v-model="valid"
       lazy-validation
       > 


		<v-row justify="start"> 
			<v-col
			lg="3"
            md="12"
            sm="12"
	        cols="12"
			>
        <v-list
          three-line
          subheader
        >
          <v-subheader >Image Metadata</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="!edit_mode">Image Title</v-list-item-title>
              <v-list-item-subtitle v-if="!edit_mode" >{{title}}</v-list-item-subtitle>
			  <v-text-field v-else
			  v-model="title"
              :rules="asset_title_rules"
              counter="75"
              hint="A title for this image"
              label="Type to change the image titlle"
              >
              </v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="!edit_mode" class="">Image Description</v-list-item-title>
              <v-list-item-subtitle v-if="!edit_mode">{{description}}</v-list-item-subtitle>
			  <v-textarea v-else
              label="Type to change the  image description"
              v-model="description"
              hint="provide brief description about this image. e.g. what kind of deterioration does it displays"
              name="input-7-1"
              >
              </v-textarea>
            </v-list-item-content>
          </v-list-item>
		   <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="!edit_mode" class="">Image tags</v-list-item-title>
              <v-list-item-subtitle v-if="!edit_mode">
               <v-chip
		 	    v-for="tag in tags" :key="tag"
      		    class="mt-2 mr-2"
                >
                {{tag}}
                </v-chip>
			  </v-list-item-subtitle>
			   <v-combobox v-else
				@change="clear_tag_search"
                v-model="tags"
                :items="pluggy_tags"
                :search-input.sync="tag_search"
                hide-selected 
                hint="Start typing to view already used tags derived from Pluggy platform"
                label="Change the tags related to this photo"
                multiple
                persistent-hint
                small-chips
                >
                    <template v-if="tag_search" v-slot:no-data>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-combobox>
            </v-list-item-content>
          </v-list-item>        
        </v-list>  
	    <v-row justify="start" no-gutters>
           <v-col
		   lg="6"
           md="12"
           sm="12"
	       cols="12"
		   >
		      <v-checkbox v-if="edit_mode" 
		       class="ml-2"
               v-model="replace_photo"
               label="Replace photo"
               >
			   </v-checkbox>
		   </v-col>
		   <v-col
		   lg="6"
           md="12"
           sm="12"
	       cols="12"
		   > 
		      <v-file-input v-if="edit_mode && replace_photo" 
			  class="ml-2 mb-4"
			  :rules="imageRules"
              accept="image/*"
              label="Asset Image" 
			  v-model= "image_file"
			  prepend-icon="mdi-camera" 
			  chips 
			  show-size
              >
			  </v-file-input>
		   </v-col>
		</v-row>


		<v-btn v-if="is_the_owner_of_asset && !edit_mode" @click="setup_edit_mode" class="mt-1 ml-4" depressed>
		    <v-icon left>
            mdi-pencil
            </v-icon>
            Edit Image Metadata
        </v-btn>  
		<v-btn v-if="is_the_owner_of_asset && !edit_mode" @click="confirm_deletion_dialog=true" class="mt-1  ml-4" depressed>
		    <v-icon left>
            mdi-delete-forever
            </v-icon>
            Delete Image
        </v-btn>  
		<v-btn v-if="is_the_owner_of_asset && edit_mode" @click="undo" class="ml-4" depressed>
		    <v-icon left>
            mdi-undo
            </v-icon>
            Undo
        </v-btn>  
		<v-btn @click="update_metadata_and_image" v-if="is_the_owner_of_asset && edit_mode" class="ml-4" depressed :disabled="!form_validated">
			<v-icon left>
            mdi-content-save
            </v-icon>
            Save
        </v-btn> 
		</v-col>
		<v-col
		lg="5"
        md="12"
        sm="12"
	    cols="12"
		>
		   <div id="map"> 
		   </div>
		</v-col>
		</v-row>

		</v-form> 
		
		<!--</v-card> -->
        <v-divider></v-divider>
		<v-row justify="start"> 
			<v-col
			lg="5"
            md="12"
            sm="12"
	        cols="12"
			>
                <v-img 
  		         max-width="800"
  		         :src="`https://pluggy.eu/api/v1/assets/`+asset_id+`/media/`+media_id"
                ></v-img>  
		    </v-col> 
		</v-row>

        <v-overlay light :value="overlay" z-index=1000 opacity="0.1">
           <v-progress-circular
           indeterminate
           color="primary"
           ></v-progress-circular>
        </v-overlay>

		<v-dialog
         v-model="confirm_deletion_dialog"
         persistent
         max-width="290"
         >
        <v-card>
           <v-card-title class="headline">
            Delete photo?
           </v-card-title>
           <v-card-text>Are you sure you want to delete the photo?</v-card-text>
           <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
            color="green darken-1"
            text
            @click="confirm_deletion_dialog = false"
            >
            No
          </v-btn>
          <v-btn
          color="green darken-1"
          text
          @click="delete_image_confirmation()"
          >
          Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


        <v-dialog
        v-model="deletion_success_dialog"
        width="500"
        >
           <v-card>
              <v-card-title class="headline grey lighten-2">
              Successful Photo Deletion
              </v-card-title>

              <v-card-text>
              The photo has been successfuly deleted.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                 <v-spacer></v-spacer>
                 <v-btn
                 color="primary"
                 text
                 @click="go_previous_page"
                 >
                 OK
                 </v-btn>
              </v-card-actions>
           </v-card>
        </v-dialog>


      </v-card>
    </v-dialog>  
  </v-row>    

  
  </v-container>
</template>


<script>
import jwt_decode from "jwt-decode";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import store from '../store'
import axios from 'axios';
import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL } from '../constants'; 

import { componentBus } from '../main';


export default {
  name: 'FullImageView',
  components: {
  
  },

  
	data: function () {
    return {

    deletion_success_dialog: false,
    confirm_deletion_dialog: false,
    overlay: false,
    image_file: null,
    valid: true,
    replace_photo: false,
    marker: null,
    asset_owner_id: null,
    logged_in_user_id: null,
    pluggy_tags: [],
    tag_search: null,
	asset_title_rules: [
        v => (v && v.length > 0)  || 'Image title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',     
    ],
	imageRules: [
        v => (!!v  && this.replace_photo) || 'Image is required',   
    ],

	edit_mode: false,
	marker: null,
	asset_id: null,
	media_id: null,
    map: null,
	dialog: true,
	tags: [],
	longitude: null,
	latitude: null,
	title: null,
	description: null,
	custom_marker: L.Marker.extend({
    options: { 
              asset_id: '',
              asset_title: ''
              }
    }),
    citizen_story_pin_icon : L.icon({
    iconUrl: "../../../../monument.svg",
    iconSize: [30, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -60]
	}),
			}},
  //}),
  mounted () {

  this.reset_validation();
  this.logged_in_user_id= jwt_decode(store.state.oidcStore.access_token.toString()).userId;
	  
 //this.map = L.map('map', { zoomControl: false} ).setView([this.longitude, this.latitude], 5).on('click', (e) => {
    //console.log(e);  
  
  //});

  this.map = L.map('map', { zoomControl: false} ).on('click', (e) => {
	if(this.edit_mode)
	{
    if(this.marker!=null) this.map.removeLayer(this.marker);  
	let icon=this.citizen_story_pin_icon;
    let m=  new this.custom_marker([e.latlng.lat,e.latlng.lng], {icon: icon, asset_id: this.asset_id, asset_title: this.asset_title }).on('click', (e) => {
	});
	this.map.addLayer( m ); 
	this.marker=m;  
    } 



    console.log(e);  
  
  });
//this.map.setView([12.43, 22.35], 5);
//.on('click', (e) => {
  //  console.log(e);  
  
  //});

 L.control.zoom({
                       position:'bottomright'
                       }).addTo(this.map);

          L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
            })
            .addTo(this.map);

	  this.asset_id= this.$route.params.asset_id;
		 this.media_id= this.$route.params.media_id;
		 console.log("here you are: "+this.asset_id+" and "+this.media_id);

    this.fetch_info(); 
  },
  methods: {

	

    update_metadata_and_image() {
    this.overlay= true;
    let list=[];
	list.push(this.update_asset_metadata());
	if(this.replace_photo)  {    list.push(this.delete_image_media());       list.push(this.replace_image());   }

    Promise.all(list)
    .then( (results) => {
    const acct = results[0];
	console.log("results: "+ acct);
	//alert("teleiose to update");
	this.undo(); 
	this.overlay=false;
  });
	},

    validate_form () {
       return this.$refs.form.validate();
      },
      reset_form () {
        this.$refs.form.reset();
      },
      reset_validation () {
        this.$refs.form.resetValidation();
      },

	replace_image() {
    let replace_image_url=APIS_BASE_URL+"/assets/"+this.asset_id+'/media';
    let replace_image_url_encoded= encodeURI(replace_image_url);
    let formData = new FormData();
    formData.append('file',this.image_file);

    return axios.post( replace_image_url_encoded,
          formData,
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (image_upload_response) =>{
                    if (image_upload_response.status == "200" )
                     {
					 console.log("image successfully uploaded!");
					 this.media_id= image_upload_response.data.id;
					
                     }
                    else
                     {
                     console.log('FAILURE with image upload upload');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with image upload!!');
                     });
	},

	delete_image_media() {
    let delete_image_media_url=APIS_BASE_URL+"/assets/"+this.asset_id+'/media/'+this.media_id;
    let delete_image_media_url_encoded= encodeURI(delete_image_media_url);
    

    return axios.delete( delete_image_media_url_encoded,
          
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (image_delete_response) =>{
                    if (image_delete_response.status == "200" )
                     {
					 console.log("image successfully deleted!");
					
                     }
                    else
                     {
                     console.log('FAILURE with image deletion');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with image deletion!!');
                     });
	},

	delete_image_confirmation() {
    let delete_image_url=APIS_BASE_URL+"/assets/"+this.asset_id;
    let delete_image_url_encoded= encodeURI(delete_image_url);
    

    return axios.delete( delete_image_url_encoded,
          
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (image_delete_response) =>{
                    if (image_delete_response.status == "200" )
                     {
					 console.log("image successfully deleted!");
					 this.deletion_success_dialog= true;

					
                     }
                    else
                     {
                     console.log('FAILURE with image deletion');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with image deletion!!');
                     });
	},

    update_asset_metadata() {
	  

	    
	  let update_asset_url=APIS_BASE_URL+"/assets/"+this.asset_id;
      let update_asset_url_encoded= encodeURI(update_asset_url);
      var payload = {};
	  payload["tags"]= this.tags;
	  payload["title"] = this.title;
	  payload["description"]= this.description;
      let location_dict= {  geo: {  coordinates: [this.marker.getLatLng().lat, this.marker.getLatLng().lng], type: "Point", zoom: 14        }             };
      payload.location= location_dict;

      return axios.put(update_asset_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
             console.log("asset metadata have been updated");
            
          }
          else {
            console.log("problem with asset metadata update ");
          }      
		  //this.undo();           
          })
          .catch(error => {
          console.log(error);
          });

	},



     fetch_info() {
     let config = {
    headers: {
      'Authorization': 'Bearer ' +store.state.oidcStore.access_token 
	},
	
   }	

	let fetch_asset_metadata=APIS_BASE_URL+"/assets/"+this.asset_id;
    let fetch_asset_metadata_encoded= encodeURI(fetch_asset_metadata);
    axios.get(fetch_asset_metadata_encoded,config)
              .then(response => {
			  this.asset_owner_id= response.data.data.owner._id; console.log("asset owner: "+this.asset_owner_id);
			  this.title= response.data.data.title;
			  this.description= response.data.data.description;
			  this.tags= response.data.data.tags;
			  this.longitude= response.data.data.location.geo.coordinates[1];
			  this.latitude= response.data.data.location.geo.coordinates[0];
			  var icon=this.citizen_story_pin_icon;

             let m=  new this.custom_marker([this.latitude,this.longitude], {icon: icon, exhibition_id: "dadasdsad",exhibition_type: "dfsf", exhibition_title: "fsdfsfsf" }).on('click', (e) => {
							
						//alert("aekara");
                });
				 this.map.addLayer( m );   
				 this.marker= m;

                 this.map.setView([this.latitude, this.longitude], 5);
 

 


 });
	 },


	   undo()
	 {
	  if(this.marker!=null) this.map.removeLayer(this.marker);  
	  this.marker=null;
	  this.replace_photo=false;
	  this.image_file=null;
	  this.edit_mode=false;
	  this.fetch_info();
	  L.DomUtil.removeClass(this.map._container,'crosshair_cursor');
	 },

	  search_pluggy_tags(text) {
	  let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
      let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
     

      axios.get(pluggy_tags_url_encoded
          ).then(response => {
          if (response.status == "200" || response.status == "201" ) {
            
             
			  console.log("pluggy_tags: "+response.data.data);
			  this.pluggy_tags=response.data.data;
              
          }
          else {
            console.log("problem with exhibition point creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	  },

	  clear_tag_search()
      {
	   this.tag_search=null;
	  },

      go_previous_page(){
		  this.$router.back();
	  },
          
     setup_edit_mode() {
	 this.edit_mode=true;
     L.DomUtil.addClass(this.map._container,'crosshair_cursor');

	 },       
               
  },
  beforeDestroy() {
    console.log("pame gia katasrofi");
    
  },

  watch: {
    
    tag_search (val) {
	   if( val!="" )
	   this.search_pluggy_tags(val);
	   else this.pluggy_tags=[];
	  }
	
    
   },

   computed: {
    // a computed getter
    is_the_owner_of_asset: function () {
      // `this` points to the vm instance
      return this.logged_in_user_id== this.asset_owner_id;
	},
	form_validated: function() {
		return this.validate_form();
	}
	
},
    
     
  
};
</script>

<style>

  #map {
        width: 100%;
        height: 280px;
        /*
        margin: 0px 0px 0px !important; */
        margin-top: 0px !important;
        padding-top: 0px !important;
       
    }
	#myimage{
		max-width:100%; height:auto
	}
 
      #box2 {width:2000px; height:100px;} 

.crosshair_cursor {
  cursor: crosshair !important;
  
}

.simple_cursor {
  cursor: '' !important;
}

</style>
