<template>
  
   <div id="mymap"  >
  
  


  </div>


  
</template>

<script>

import 'leaflet';



import { ATTRIBUTION, MAP_URL, APIS_BASE_URL } from '../constants';
import router from '../router';
import axios from 'axios';
import { componentBus } from '../main';
import store from '../store';
import jwt_decode from "jwt-decode";
import { mapGetters, mapActions } from 'vuex'
// added 7/1/2021


const L = window.L;

export default {
  name: 'InteractiveMap',
 
  data: () => ({
	  experimental: 3,
	   citizen_story_pins:{},
  business_story_pins: {},

	mymap:null,
	custom_marker: L.Marker.extend({
    options: { 
              exhibition_id: '',
              exhibition_type: '',
              exhibition_title: ''
              }
    }),
    citizen_story_pin_icon : L.icon({
    iconUrl: "monument.svg",
    iconSize: [30, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -60]
	}),
	/*
    business_story_pin_icon : L.icon({
    iconUrl: 'business.svg',
    

    iconSize:     [38, 95], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
})  */
   business_story_pin_icon : L.icon({
    iconUrl: 'business.svg',
    iconSize:     [30, 40], // size of the icon
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -60] // point from which the popup should open relative to the iconAnchor
})  
  
    
  }),
  mounted() {

  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });
  
	//console.log("iparxei "+store.state.oidcStore.access_token);
    this.mymap = L.map('mymap', { zoomControl: false} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
    console.log(e);  
  
  });
  L.control.zoom({
                       position:'bottomright'
                       }).addTo(this.mymap);

          L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
            })
            .addTo(this.mymap);

	 let config = {
    headers: {
      'Authorization': 'Bearer ' +store.state.oidcStore.access_token 
    }
  }		
  let fetch_citizen_stories_url=APIS_BASE_URL+"/exhibitions/?kind=ContentExhibition&limit=1000&type=hyperion_citizen_story";
  let fetch_citizen_stories_url_encoded= encodeURI(fetch_citizen_stories_url);
  
            axios.get(fetch_citizen_stories_url_encoded,config)
              .then(response => {
                  response.data.data.result
                  .forEach(val => {
						
                var icon=this.citizen_story_pin_icon;
				
				console.log("check: "+this.experimental);
				console.log(this.citizen_story_pin_icon);
				console.log("my val: "+val["_id"]);
                let m=  new this.custom_marker([val.location.geo.coordinates[1],val.location.geo.coordinates[0]], {icon: icon, exhibition_id: val.id, exhibition_type: val.type, exhibition_title: val.title }).on('click', (e) => {
							 //alert(val["_id"]);
							// alert("aek");
							this.$router.push('/citizen_story_view/'+val["_id"]);
                });
                       
				this.mymap.addLayer( m );
				this.citizen_story_pins[val.id]=m;
                });  
 });

  let fetch_business_stories_url=APIS_BASE_URL+"/exhibitions/?kind=ContentExhibition&limit=1000&type=hyperion_business_owners_story";
  let fetch_business_stories_url_encoded= encodeURI(fetch_business_stories_url);
  
            axios.get(fetch_business_stories_url_encoded,config)
              .then(response => {
                  response.data.data.result
                  .forEach(val => {
						
                var icon=this.business_story_pin_icon;
				
                let m=  new this.custom_marker([val.location.geo.coordinates[1],val.location.geo.coordinates[0]], {icon: icon, exhibition_id: val.id, exhibition_type: val.type, exhibition_title: val.title }).on('click', (e) => {
                             this.$router.push('/business_story_view/'+val["_id"]);
                });
                       
				this.mymap.addLayer( m );
				this.business_story_pins[val.id]=m;
                });  
 }); 
						
  },
  methods: {
...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'removeOidcUser',  
    ])
  },
  destroyed() {  
  this.mymap.remove();
}

};
</script>

<style>
@import "../../node_modules/leaflet/dist/leaflet.css";
 #mymap {
        width: 100%;
        height: 850px;
        /*
        margin: 0px 0px 0px !important; */
        margin-top: 0px !important;
        padding-top: 0px !important;
       
    }

</style>