<template>
 <v-container>
    <v-alert
      border="top"
      color="red lighten-2"
      dark
    >
      This is the callback
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'  
import store from '../store'
import jwt_decode from "jwt-decode";
import { componentBus } from '../main';


export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore',[
      'oidcSignInCallback'
    ])
  },
  computed: {
          ...mapGetters('oidcStore',[
      'oidcIsAuthenticated','oidcUser','oidcAccessToken'
    ])

  

  },
  mounted () {
    //alert("are you here?");
    //console.log('mpika mesa');
    this.oidcSignInCallback()
      .then((redirectPath) => {
       
//console.log("redirect path:"+ redirectPath);
        this.$router.push(redirectPath)
        //console.log(this.oidcAccessToken);
		let decoded_token= jwt_decode(this.oidcAccessToken);
		
        //console.log(decoded);
        componentBus.$emit('decoded_token', decoded_token);
        //console.log(decoded)
      })
      .catch((err) => {
        console.error(err)
        this.$router.push('/oidc-callback-error') // Handle errors any way you want
      })
    
     
  }
}

</script>