<template>
  <v-container>
    <v-alert
      border="top"
      color="red lighten-2"
      dark
    >
      I'm an alert with a top border and red color
    </v-alert>
  </v-container>
</template>

<script>
import store from '../store'



import axios from 'axios';
import {  APIS_BASE_URL } from '../constants';
export default {
  name: 'Test',

  data: () => ({
  
    
   
  }),
  mounted () {
      alert("nikos");

      let token= store.state.oidcStore.access_token;
      //token='sadad';
     
      let get_assets=APIS_BASE_URL+"/assets/my";
      let get_assets_url_encoded= encodeURI(get_assets);

      axios.get(get_assets_url_encoded, {
  headers: {
     'Authorization': `Bearer ${token}`
  }
})
        .then(response => {
           
        response.data.data.result.forEach(function (item, index) {
        console.log(item["_id"], index);
        });
        }).catch(error => console.log(error));
  }
};
</script>
