
/*
export const oidcSettings = {
	
	//authority: 'https://pluggy.eu/oauth2/auth/',
	//authority: 'http://127.0.0.1:9999/authority',
	authority: 'http://hyperion.iccs.gr/authority',
	//silentRedirectUri: 'https://pluggy.eu/oauth2/auth',
	//automaticSilentRenew: true, 
	//automaticSilentSignin: true,
	
	//authority: 'https://pluggy.eu',
	clientId: 'hyperion_client_id',
	//authority: 'https://nextgen.iccs.gr/auth/realms/NextGenCMSRealm',
	//clientId: 'cms',
	redirectUri: 'http://localhost:8080/oidc_callback',
	//responseType: 'id_token token',
	responseType: 'code',
	//responseType: 'id_token',
	//scope: 'openid profile'
	scope: 'read write',
	

  }
*/

  export const oidcSettings=    process.env.NODE_ENV === 'production' ? 
  
  {	
	authority: 'https://hyperion.iccs.gr/authority',
	clientId: 'hyperion_client_id_production',
	redirectUri: 'https://hyperion.iccs.gr/oidc_callback',
	responseType: 'code',
	scope: 'read write',
  }  : 
  
  {	
	authority: 'https://hyperion.iccs.gr/authority',
	clientId: 'hyperion_client_id',
	redirectUri: 'http://localhost:8080/oidc_callback',
	responseType: 'code',
	scope: 'read write',
  };