<template>
  <v-container>
     <horizontal-stepper :steps="demoSteps" @completed-step="completeStep" @active-step="isStepActive" @stepper-finished="alert">                     
     </horizontal-stepper>
  </v-container>
</template>


<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import store from '../store'
import axios from 'axios';
import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL } from '../constants'; 
import HorizontalStepper from 'vue-stepper';
import CitizenStoryFirstStep from './CitizenStoryFirstStep.vue';
import CitizenStorySecondStep from './CitizenStorySecondStep.vue';
import CitizenStoryThirdStep from './CitizenStoryThirdStep.vue';
import { componentBus } from '../main';


export default {
  name: 'CitizenStoryCreation',
  components: {
  HorizontalStepper
  },

  data: () => ({
    mymap: null,
    exhibition_title: null,
    exhibition_description: null,
    e1: 1,

    exhibition_title_rules: [
        v => (v && v.length > 0)  || 'Story title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',
        
    ],
    exhibition_description_rules: [
        v => (v && v.length > 0)  || 'Story description is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
    ],
    demoSteps: [
                    {
                        icon: 'subtitles',
                        name: 'first',
                        title: 'Citizen Story Main Info',
                        subtitle: 'Title, description and location',
                        component: CitizenStoryFirstStep,
                        completed: false

                    },
                 /*   {
                        icon: 'report_problem',
                        name: 'second',
                        title: 'CH Site Deterioration',
                        subtitle: 'risk, criticality, priority ',
                        component: CitizenStorySecondStep,
                        completed: false
					},    */
					{
                       icon: 'image',
                        name: 'third',
                        title: 'Images of CH site',
                        subtitle: 'images uploading and their location',
                        component: CitizenStoryThirdStep,
                        completed: false
                    }
                ]
   
  }),
  mounted () {
    /*
    this.leaflet_dom_util=  L.DomUtil;
    this.mymap = L.map('mymap', { zoomControl: true} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
    console.log(e);  
    alert("hello");
  });
 L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
            })
            .addTo(this.mymap);
*/
  },
  methods: {

      completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
                componentBus.$emit('completed_step', payload);
            },
            // Executed when @active-step event is triggered
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            // Executed when @stepper-finished event is triggered
            alert(payload) {

                componentBus.$emit('finished', true);
          
                // this is when stepper has finished...

                //alert('end')
                //this.$router.push('/');
                
            }
  },
  beforeDestroy() {
    console.log("pame gia katasrofi");
    
  }
    
     
  
};
</script>

<style>
 
 /*#mymap {
        width: 300px;;
        height: 400px;
        /*
        margin: 0px 0px 0px !important; 
      
    }*/
       
</style>
