<template>
    
<v-card 
      class="d-flex justify-center"
      color=""
      flat
      tile
    >
  <div id="search_element" class="leaflet-control">


   <v-toolbar
    dark
    
  >
    <v-toolbar-title>Citizen Story Selection</v-toolbar-title>
    <v-autocomplete
      return-object
      v-on:change="citizen_story_selected"
      full-width
      v-model="select"
      :loading="loading"
      :items="my_items"
      :search-input.sync="search"
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Start typing the name of the Citizen Story you want to view.."
      solo-inverted
    ></v-autocomplete>
  
  </v-toolbar>

</div>
</v-card>



</template>

<script>
    import { componentBus } from '../main';
    import router from '../router';
    import axios from 'axios';
    import {  APIS_BASE_URL } from '../constants';
    export default {
    
	name: 'SearchCitizenStory',
    data: function () {
    return {
    
	loading: false,
    my_items: [],
    search: null,
    select: null,
    };
    },

    watch: {
    search (val) {
	val && val !== this.select && this.querySelections(val)
	if(!val) this.get_all_citizen_stories();
    }
    },
        
	computed: {
    },
    
	methods: {
  
    querySelections (v) {
    this.my_items.splice(0,this.my_items.length);
    var citizen_story_search_url=APIS_BASE_URL+"/search/?q="+v;
    var citizen_story_search_url_encoded= encodeURI(citizen_story_search_url);
    this.loading = true
   
    axios.get(citizen_story_search_url_encoded)
    .then(response => {
    response.data.data.forEach(val => {
    let object = {
        text: val.title,
        value: val["_id"]
    };
    this.my_items.push(object);
    this.loading=false;
                   
    });
                   
    })
    .catch(error => console.log(error)); 
       
          
    },
        
    citizen_story_selected: function (event) {
    // `this` inside methods points to the Vue instance
    //alert("malaka");
    console.log("value: "+event.value);
    console.log("text: "+ event.text);
    //let payload= {};
    //payload["point_uuid"]= event.value;
    //payload["relevant_project"]=event.relevant_project;
    //componentBus.$emit('render_relevant_pois_and_range_and_focus_on_map', payload);

    if(router.currentRoute.path!="/citizen_story_view")
    router.push({ path: 'citizen_story_view/'+event.value })
    // `event` is the native DOM event
     
    },
        
    get_all_citizen_stories() 
	{
    var all_citizen_stories_search_url=APIS_BASE_URL+"/exhibitions/?kind=ContentExhibition&limit=1000&type=hyperion_citizen_story";
    var all_citizen_stories_search_url_encoded= encodeURI(all_citizen_stories_search_url);
    this.loading = true
   
    axios.get(all_citizen_stories_search_url_encoded)
    .then(response => {
    response.data.data.result.forEach(val => {
    let object = {
    text: val.title,
    value: val["_id"],
    };
    this.my_items.push(object);
    this.loading=false;
                   
    });
                   
    })
    .catch(error => console.log(error)); 
	},
           
    },
    mounted() {
    this.get_all_citizen_stories();
    
           
    },
    destroyed() {
           
   
    }
    };
</script>

<style scoped>
#search_element {
 position: fixed;
 bottom: 0;
 margin-bottom: 50px;
 width: 850px;


}
</style>
