<template>
 
<v-card>
   <v-row dense class="">
      <v-col cols="12">
         <v-img
         :src="require('../../public/monument.svg')"
         contain
         height="100"
         />
      </v-col>
      <v-col class="">
         <h1 class="display-2 font-weight-bold text-center">
         Hyperion Citizen Story
         </h1>
      </v-col>
   </v-row>

   <v-tabs
    @change="tab_changed"
    v-model="tab"
    background-color="#695E4A"
    centered
    dark
    icons-and-text
    >
       <v-tabs-slider></v-tabs-slider>

       <v-tab href="#tab-info">
           {{first_tab_text}}
          <v-icon>mdi-information</v-icon>
       </v-tab>
       <v-tab href="#tab-photos">
           {{second_tab_text}}
          <v-icon>mdi-image</v-icon>
       </v-tab>
    </v-tabs>

   <v-row dense>
	   <v-col 
	   lg="8"
       md="12"
       sm="12"
	   cols="12"
	   > 
           <v-tabs-items v-model="tab">
               <v-tab-item
               value="tab-info"
               >
                  <v-card flat>
                      <v-row dense class="">
                          <v-col
						  lg="4"
                          md="12"
                          sm="12"
		                  cols="12"
						  >
                             <h2 class="headline font-weight-bold mx-2 mb-lg-3">
                             Story Title:
                             </h2>
                          </v-col>
                          <v-col 
						  lg="8"
                          md="12"
                          sm="12"
		                  cols="12"
						  >
		                     <h3 v-if="!edit_mode" class="mx-2 break_sentence subheading font-weight-regular">
                             {{exhibition_title}}
                             </h3>
							 <v-text-field v-else
                      	     v-model="exhibition_title"
                             :rules="exhibition_title_rules"
                             counter="75"
                             hint="name of monument, place, etc."
                             label="Type to change the title"
                             >
                             </v-text-field>
                          </v-col>
                       </v-row>
                       <v-row dense  class="">
			              <v-col 
						  lg="4"
                          md="12"
                          sm="12"
		                  cols="12"
						  >
				             <h2 class="headline mx-2 font-weight-bold mb-lg-3">
				             Story Description:
				             </h2>
			              </v-col>
			              <v-col
						  lg="8"
                          md="12"
                          sm="12"
		                  cols="12"
						  >
				              <h3 v-if="!edit_mode" class="mx-2 break_sentence subheading font-weight-regular">
				              {{exhibition_description}}
				              </h3>
							  <v-textarea v-else
                              label="Type to change the description"
                              v-model="exhibition_description"
                              hint="provide brief description about the deterioration of the monument, etc. place, kind of monument, urgency, etc."
                              :rules="exhibition_description_rules"
                              name="input-7-1"
                              >
                              </v-textarea>
			              </v-col>
		               </v-row>
					  
                       <v-row dense  class="">
                           <v-col 
						   lg="4"
                           md="12"
                           sm="12"
		                   cols="12"
						   >
                               <h2 class="mx-2 headline font-weight-bold mb-lg-3">
                               Tags:
                               </h2>
                           </v-col>
                           <v-col 
						   lg="8"
                           md="12"
                           sm="12"
		                   cols="12"
						   >
		                       <h3 v-if="!edit_mode" class="mx-2 subheading font-weight-regular">
         	                       <v-chip
		 	                        v-for="tag in exhibition_tags" :key="tag"
      		                        class="mr-2"
                                    >
                                    {{tag}}
                                   </v-chip>
                               </h3>
							   <v-combobox v-else
						       @change="clear_tag_search"
                               v-model="exhibition_tags"
                               :items="pluggy_tags"
                               :search-input.sync="tag_search"
                               hide-selected 
                               hint="Start typing to view already used tags derived from Pluggy platform"
                               label="Change the tags related to the monument deterioration"
                               multiple
                               persistent-hint
                               small-chips
                               >
                               <template v-if="tag_search" v-slot:no-data>
                                   <v-list-item>
                                       <v-list-item-content>
                                           <v-list-item-title>
                                            No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                           </v-list-item-title>
                                       </v-list-item-content>
                                   </v-list-item>
                               </template>
                           </v-combobox>
                           </v-col>
                       </v-row>
                  </v-card>
               </v-tab-item>

	           <v-tab-item
               value="tab-photos"
               >
                   <v-container fluid>
          <v-row>
            <v-col
              v-for="(asset,index) in assets"
              :key="index"
              cols="12" xs="3" sm="3" md="3" lg="2" xl="2"
            >
              <v-img
			    class="thumbnail"
                :src="`https://pluggy.eu/api/v1/assets/${asset.id}/media/${asset.mediaContent[0]._id}/thumbnail`"
                @click="$router.push('/full_image_view/asset/'+asset.id+'/media/'+asset.mediaContent[0]._id);"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
               </v-tab-item>
           </v-tabs-items>



          

       </v-col> 
       <v-col 
	   lg="4"
       md="12"
       sm="12"
	   cols="12"
	   > 
		
	       <div id="map">
           </div> 
		   
		   <v-btn @click="setup_edit_mode" v-if="is_info_tab && is_the_owner_of_story && !edit_mode" class="mt-2" depressed>
			   <v-icon left>
               mdi-pencil
               </v-icon>
               Edit Story
           </v-btn> 
		   <v-btn @click="confirm_deletion_dialog=true" v-if="is_the_owner_of_story && !edit_mode" class="mt-2 ml-lg-2" depressed>
			   <v-icon left>
               mdi-delete-forever
               </v-icon>
               Delete Story
           </v-btn> 
		   <v-btn @click="$router.push('/photo_addition/exhibition/'+exhibition_id+'/exhibition_point_id/'+exhibition_point_id)" v-if="is_the_owner_of_story && !edit_mode && !is_info_tab" class="mt-2 ml-lg-2" depressed>
			   <v-icon left>
               mdi-file-image
               </v-icon>
               Add photo
           </v-btn> 
		   <v-btn @click="confirm_publication_dialog=true" v-if="!has_been_published && is_the_owner_of_story && !edit_mode" class="mt-2 ml-lg-2" depressed>
			   <v-icon left>
               mdi-publish
               </v-icon>
               Publish Story
           </v-btn>
		   <v-btn @click="undo" v-if="is_the_owner_of_story && edit_mode" class="mt-2" depressed>
			   <v-icon left>
               mdi-undo
               </v-icon>
               Undo
           </v-btn> 
		   <v-btn @click="update" v-if="is_the_owner_of_story && edit_mode" class="mt-2 ml-lg-2" depressed>
			   <v-icon left>
               mdi-content-save
               </v-icon>
               Save
           </v-btn> 
	   </v-col>
</v-row>

<v-dialog
 v-model="confirm_deletion_dialog"
 persistent
 max-width="290"
 >
    <v-card>
        <v-card-title class="headline">
         Delete story?
        </v-card-title>
        <v-card-text>Are you sure you want to delete the story?</v-card-text>
           <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
            color="green darken-1"
            text
            @click="confirm_deletion_dialog = false"
            >
            No
          </v-btn>
          <v-btn
          color="green darken-1"
          text
          @click="delete_story_confirmation()"
          >
          Yes
          </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>


<v-dialog
        v-model="deletion_success_dialog"
        width="500"
        >
           <v-card>
              <v-card-title class="headline grey lighten-2">
              Successful Story Deletion
              </v-card-title>

              <v-card-text>
              The story has been successfuly deleted.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                 <v-spacer></v-spacer>
                 <v-btn
                 color="primary"
                 text
                 @click="go_previous_page"
                 >
                 OK
                 </v-btn>
              </v-card-actions>
           </v-card>
        </v-dialog>


<v-dialog
 v-model="confirm_publication_dialog"
 persistent
 max-width="290"
 >
    <v-card>
        <v-card-title class="headline">
         Publish story?
        </v-card-title>
        <v-card-text>Are you sure you want to publish the story in Pluggy platform?</v-card-text>
           <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
            color="green darken-1"
            text
            @click="confirm_publication_dialog = false"
            >
            No
          </v-btn>
          <v-btn
          color="green darken-1"
          text
          @click="publish_story_confirmation()"
          >
          Yes
          </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>


<v-dialog
        v-model="publication_success_dialog"
        width="500"
        >
           <v-card>
              <v-card-title class="headline grey lighten-2">
              Successful Story Publication
              </v-card-title>

              <v-card-text>
              The story has been successfuly published in Pluggy platform.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                 <v-spacer></v-spacer>
                 <v-btn
                 color="primary"
                 text
                 @click="publication_success_dialog=false"
                 >
                 OK
                 </v-btn>
              </v-card-actions>
           </v-card>
        </v-dialog>






</v-card>
</template>

<script>
import jwt_decode from "jwt-decode";
import moment from 'moment';
//import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";




import store from '../store'
import axios from 'axios';
import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL } from '../constants'; 
import { componentBus } from '../main';
import { mapGetters, mapActions } from 'vuex'


export default {
  name: 'CitizenStoryView',
  components: {
  
  },

  data: () => ({
	
	public: null,
	confirm_publication_dialog: false,
	publication_success_dialog: false,

	confirm_deletion_dialog: false,
	deletion_success_dialog: false,
	marker: null,
	pluggy_tags: [],
	tag_search: null,
	//priority_items: [ { text: 'Urgent', value: 5}, {text: 'High', value: 4},{text: 'Medium', value: 3} ,{text: 'Low', value: 2}, {text: 'Very low', value: 1}],
	//criticality_items: [ { text: "Very high", value: 5},{ text: "High", value: 4},{ text: "Medium", value: 3},{ text: "Low", value: 2}, { text: "Very low", value: 1 }],
	exhibition_title_rules: [
        v => (v && v.length > 0)  || 'Story title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',
        
	],
	exhibition_description_rules: [
        v => (v && v.length > 0)  || 'Story description is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
	],
	/*monument_damage_description_rules: [
        v => (v && v.length > 0)  || 'Monument damage description is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
	],
	monument_risk_description_rules: [
        v => (v && v.length > 0)  || 'Risk assessment is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
	],
	people_risk_description_rules: [    
        v => (v && v.length > 0)  || 'Desciption regarding people risk in surrounding area is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',          
        
  ],
    priority_rules: [
        v => (v && v > 0)  || 'Priority of the issue is required',     
  ],
  criticality_rules: [
        v => (v && v > 0)  || 'Criticality of the issue is required', 
  ], */
    edit_mode: false,
    logged_in_user_id: null,
    render_full_image: false,
    assets: [],
    tab: null,
	map: null,
	custom_marker: L.Marker.extend({
    options: { 
              exhibition_id: '',
              exhibition_type: '',
              exhibition_title: ''
              }
    }),
    citizen_story_pin_icon : L.icon({
    iconUrl: "../monument.svg",
    iconSize: [30, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -60]
	}),
	exhibition_owner_id: null,
	exhibition_id: null,
    exhibition_title: null,
	exhibition_description: null,
	exhibition_latitude: null,
	exhibition_longitude: null,
	/*exhibition_point_monument_damage: null,
	exhibition_point_monument_risk: null,
	exhibition_point_people_risk: null,
	exhibition_point_priority: null,
	exhibition_point_priority_number_format: null,
	exhibition_point_criticality_number_format: null,
	exhibition_point_criticality: null,*/
	//exhibition_tags: null,
  exhibition_tags: [],
	exhibition_point_id: null
    

    
   
   
  }),
  mounted () {

  //console.log(moment("22-06-2015", "DD-MM-YYYY", true).isValid());
	
  this.logged_in_user_id= jwt_decode(store.state.oidcStore.access_token.toString()).userId;
  
  	
	
  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });
      
    this.exhibition_id= this.$route.params.id;
	
	  this.map = L.map('map', { zoomControl: false, attributionControl: true} ).setView([57.165129, 19.433151], 3).on('click', (e) => {
		  if(this.edit_mode)
		  {
		   if(this.marker!=null) this.map.removeLayer(this.marker);  
		   let icon=this.citizen_story_pin_icon;
           let m=  new this.custom_marker([e.latlng.lat,e.latlng.lng], {icon: icon, exhibition_id: this.exhibition_id,exhibition_type: "dfsf", exhibition_title: this.exhibition_title }).on('click', (e) => {
		   });
			this.map.addLayer( m ); 
			this.marker=m;  

		  }
	   });
             L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c'],    
            })
            .addTo(this.map);
            //L.control.attribution.addAttribution('fsfsf');
           // L.control.attribution({position: 'topleft'}).addAttribution(ATTRIBUTION).addTo(this.map);
            L.control.zoom({ position:'bottomright'
            }).addTo(this.map); 

	
	
  this.fetch_info();


   
 




  },
  methods: {

	


	publish_story_confirmation() {
	  
     let list= [this.publish_exhibition_only(),this.publish_exhibition_point_only()];
     this.assets.forEach( (item, index) => {
     list.push(this.publish_asset(item.id));
     });

	 Promise.all(list)
    .then(  (results) => {
    const acct = results[0];
	console.log("results: "+ acct);
	this.confirm_publication_dialog=false;
    this.publication_success_dialog=true;
	this.public=true;
	//alert("teleiose to update");
  });

	},

	publish_exhibition_only() {

	 let publish_exhibition_only_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id;
     let publish_exhibition_only_url_encoded= encodeURI(publish_exhibition_only_url);
     var payload = {};
	 payload["public"]= true;

     axios.put(publish_exhibition_only_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
             console.log("exhibition main info has been published to pluggy");
            
          }
          else {
            console.log("problem with exhibition main info publication ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	},



	publish_exhibition_point_only() {

	 let publish_exhibition_point_only_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+"/exhibition-points/"+this.exhibition_point_id;
     let publish_exhibition_point_only_url_encoded= encodeURI(publish_exhibition_point_only_url);
     var payload = {};
	 payload["public"]= true;

     axios.put(publish_exhibition_point_only_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
             console.log("exhibition point has been published to pluggy");
            
          }
          else {
            console.log("problem with exhibition point publication ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	},

	publish_asset(asset_id) {

	 let publish_asset_url=APIS_BASE_URL+"/assets/"+asset_id;
     let publish_asset_url_encoded= encodeURI(publish_asset_url);
     var payload = {};
	 payload["public"]= true;

     axios.put(publish_asset_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
             console.log("asset has been published to pluggy");
            
          }
          else {
            console.log("problem with asset publication ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	},

	go_previous_page(){
	this.$router.back();
	},

	delete_story_confirmation() {
    let delete_exhibition_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id;
    let delete_exhibition_url_encoded= encodeURI(delete_exhibition_url);
    

    return axios.delete( delete_exhibition_url_encoded,
          
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (story_delete_response) =>{
                    if (story_delete_response.status == "200" )
                     {
					 console.log("story successfully deleted!");
					 this.deletion_success_dialog= true;

					
                     }
                    else
                     {
                     console.log('FAILURE with story deletion');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with story deletion!!');
                     });
	},

	update() {
    Promise.all([this.main_info_update(),this.exhibition_point_content_update(),this.exhibition_point_main_info_update()])
    .then(function (results) {
    const acct = results[0];
	console.log("results: "+ acct);
	//alert("teleiose to update");
  });
	},

    exhibition_point_main_info_update() {
	  
	  let exhibition_point_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+"/exhibition-points/"+this.exhibition_point_id;
      let exhibition_point_url_encoded= encodeURI(exhibition_point_url);
      var payload = {};
	  payload["tags"]= this.exhibition_point_tags;

      axios.put(exhibition_point_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
             console.log("exhibition point main info update");
            
          }
          else {
            console.log("problem with exhibition point main info update ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	},



	exhibition_point_content_update() {

	  let update_exhibition_point_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+"/exhibition-points/"+this.exhibition_point_id+"/content";  
      let update_exhibition_point_url_encoded= encodeURI(update_exhibition_point_url);
      let payload= {};
      payload["monument_damage_description"]= this.exhibition_point_monument_damage;
      payload["monument_risk_description"]= this.exhibition_point_monument_risk;
      payload["people_risk_description"]= this.exhibition_point_people_risk;
      payload["priority"]= this.exhibition_point_priority_number_format;
      payload["criticality"]= this.exhibition_point_criticality_number_format;
      let payload_string= JSON.stringify(payload);
      let blob = new Blob([payload_string], { type: 'application/json' });
      let file = new File([blob], "content.json", {type: "application/json"});

      let formData = new FormData();
      //formData.append('file',this.image_file);
      formData.append('file',file);

                                axios.post( update_exhibition_point_url_encoded,
                                 formData,
                                 {
                                 headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                                 }
                          
                                ).then( (content_upload_response) =>{

                                if (content_upload_response.status == "200" )
                                   {
                                    console.log("content successfully uploaded!");
                                   }
                               
                                else
                                 {
                                  console.log('FAILURE with exhibition point content upload');
                                 }
                                  })
                                 .catch( () =>{
                                   console.log('failure with exhibition point content upload!!');
                                   });

	},

	main_info_update() {
    let update_exhibition_main_info_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id;
    let update_exhibition_main_info_url_encoded= encodeURI(update_exhibition_main_info_url);
    var payload = {};
    //this.exhibition_title   this.exhibition_description
    payload.title= this.exhibition_title;
	payload.description= this.exhibition_description;
  let hyperion_story_tag_exists=false;    // we will check whether "Hyperion Story" and "DD-MM-YYYY" tags exist. We do not want the user to remove those tags
  let date_tag_exists=false;
  this.exhibition_tags.forEach(function (item, index) {
  //console.log(item, index);
  if (item=="Hyperion Story") hyperion_story_tag_exists= true;
  else if(   moment(item, "DD-MM-YYYY", true).isValid()  ) date_tag_exists=true; 
  });
  if(!hyperion_story_tag_exists) this.exhibition_tags.push("Hyperion Story");
  if(!date_tag_exists) this.exhibition_tags.push(   this.the_date_today );  
	payload.tags= this.exhibition_tags;
    //payload.exhibitionPoints[0].tags=this.exhibition_point_tags;
	console.log("marker location "+this.marker.getLatLng().lng);
    let location_dict= {  geo: {  coordinates: [this.marker.getLatLng().lat, this.marker.getLatLng().lng], type: "Point", zoom: 14        }             };
    payload.location= location_dict;
	//payload.type= "hyperion_citizen_story";
    //payload.saved=true;
    //payload.public=false;



    return axios.put(update_exhibition_main_info_url_encoded, JSON.stringify(  payload),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition main info has been updated");
              //console.log("request success: "+ response.data.success);
              
          }
          else {
            console.log("problem with exhibition main info update ");
		  }   
		  this.undo();              
          })
          .catch(error => {
          console.log(error);
          });
	 },

	 tab_changed() {
	 if(this.edit_mode)
     this.undo();
	 },

	 setup_edit_mode() {
	 this.edit_mode=true;
     L.DomUtil.addClass(this.map._container,'crosshair_cursor');

	 },

	 search_pluggy_tags(text) {
	  let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
      let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
     

      axios.get(pluggy_tags_url_encoded
          ).then(response => {
          if (response.status == "200" || response.status == "201" ) {
            
             
			  console.log("pluggy_tags: "+response.data.data);
			  this.pluggy_tags=response.data.data;
              
          }
          else {
            console.log("problem with exhibition point creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	  },

	 clear_tag_search()
      {
	   this.tag_search=null;
	  },

    ...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'removeOidcUser',  
	]),
	
		 
	 fetch_info() {

    let config = {
    headers: {
      'Authorization': 'Bearer ' +store.state.oidcStore.access_token 
	},
	
  }	
	 
	 let fetch_citizen_story_url=APIS_BASE_URL+"/exhibitions/"+this.$route.params.id;
     let fetch_citizen_story_url_encoded= encodeURI(fetch_citizen_story_url);
     axios.get(fetch_citizen_story_url_encoded,config)
              .then(response => {
			  this.exhibition_owner_id= response.data.data.owner._id; console.log("exhibition owner: "+this.exhibition_owner_id);
			  this.assets= response.data.data.exhibitionPoints[0].assets;
			  this.exhibition_title= response.data.data.title;
			  this.exhibition_description= response.data.data.description;
			  this.exhibition_longitude= response.data.data.location.geo.coordinates[1];
			  this.exhibition_latitude= response.data.data.location.geo.coordinates[0];
			  //this.exhibition_point_tags= response.data.data.exhibitionPoints[0].tags;  // its a list
              this.exhibition_tags= response.data.data.tags;  // its a list
			  this.exhibition_point_id= response.data.data.exhibitionPoints[0].id;
			  this.public= response.data.data.public;
			  var icon=this.citizen_story_pin_icon;

             let m=  new this.custom_marker([this.exhibition_latitude,this.exhibition_longitude], {icon: icon, exhibition_id: this.exhibition_id,exhibition_type: "dfsf", exhibition_title: this.exhibition_title }).on('click', (e) => {
							
						//alert("aekara");
                });
			this.map.addLayer( m ); 
			this.marker=m;  
//var marker = L.marker([51.5, -0.09]).addTo(this.map);



			  //this.download_exhibition_point_content();
 }); 
	 },

     // we will not use this method right now
     download_exhibition_point_content(){

		let fetch_citizen_story_content_url=APIS_BASE_URL+"/exhibition-points/"+this.exhibition_point_id+"/content";
        let fetch_citizen_story_content_url_encoded= encodeURI(fetch_citizen_story_content_url);
		axios({
        url: fetch_citizen_story_content_url_encoded, 
        method: 'GET',
        responseType: 'blob' 
        }).then((response) => {
		
			var reader = new FileReader();
			reader.readAsText(response.data); 
			//let content_json_string = reader.toString();
			reader.onload=() => {
			let exhibition_point_content_json = JSON.parse(reader.result); 
			console.log(reader.result);
			this.exhibition_point_monument_damage= exhibition_point_content_json.monument_damage_description;
			this.exhibition_point_monument_risk= exhibition_point_content_json.monument_risk_description;
			this.exhibition_point_people_risk= exhibition_point_content_json.people_risk_description;
			console.log(exhibition_point_content_json.priority);
			switch(exhibition_point_content_json.priority) {
				case 5:
					this.exhibition_point_priority= "Urgent";
					this.exhibition_point_priority_number_format=5;
					break;
				case 4:
					this.exhibition_point_priority= "High";
					this.exhibition_point_priority_number_format=4;
					break;
				case 3:
					this.exhibition_point_priority= "Medium";
					this.exhibition_point_priority_number_format=3;
					break;
				case 2:
					this.exhibition_point_priority= "Low";
					this.exhibition_point_priority_number_format=2;
					break;
				case 1:
					this.exhibition_point_priority= "Very Low";	
					this.exhibition_point_priority_number_format=1;	
			}
			switch(exhibition_point_content_json.criticality) {
				case 5:
					this.exhibition_point_criticality= "Very High";
					this.exhibition_point_criticality_number_format=5;	
					break;
				case 4:
					this.exhibition_point_criticality= "High";
					this.exhibition_point_criticality_number_format=4;	
					break;
				case 3:
					this.exhibition_point_criticality= "Medium";
					this.exhibition_point_criticality_number_format=3;	
					break;
				case 2:
					this.exhibition_point_criticality= "Low";
					this.exhibition_point_criticality_number_format=2;	
					break;
				case 1:
					this.exhibition_point_criticality= "Very Low";	
					this.exhibition_point_criticality_number_format=1;		
			}
			
			}
    
			});

	 },
	 undo()
	 {
	  if(this.marker!=null) this.map.removeLayer(this.marker);  
	  this.marker=null;
	  this.edit_mode=false;
	  this.fetch_info();
	  L.DomUtil.removeClass(this.map._container,'crosshair_cursor');
	 },
            // Executed when @active-step event is triggered
           
            // Executed when @stepper-finished event is triggered

  pad2(n) {
        return ( n < 10 ? '0' : '' ) + n;
      }
          
  },
  
  beforeDestroy() {
    console.log("pame gia katasrofi");
 
  },
   computed: {
    // a computed getter
    is_the_owner_of_story: function () {
      // `this` points to the vm instance
      return this.logged_in_user_id== this.exhibition_owner_id;
	},
	is_info_tab: function() {
	return this.tab=='tab-info';
	},

	has_been_published: function() {
	return this.public==true;
	},

	first_tab_text () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "Info"
          case 'sm': return "Info"
          case 'md': return "Story Information"
          case 'lg': return "Story Information"
          case 'xl': return "Story Information"
        }
      },

	second_tab_text () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "Photos"
          case 'sm': return "Photos"
          case 'md': return "Story Photos"
          case 'lg': return "Story photos"
          case 'xl': return "Story Photos"
        }
      },
  the_date_today() {
      let date = new Date();
      let month = this.pad2(date.getMonth()+1);
      let day= this.pad2(date.getDate());
      let year= date.getFullYear();
      let formattedDate = day+"-"+month+"-"+year;
      return formattedDate;
      }

	
},

watch: {
    
    tag_search (val) {
	   if( val!="" )
	   this.search_pluggy_tags(val);
	   else this.pluggy_tags=[];
	  },
	tab (val) {
		console.log("tab is "+this.tab);
	}
    
   }
  
}; 
</script>

<style>
 

.image_dialog{
  position: absolute;
 left: 200px;
  top: 200px;
}

 .break_sentence {
  word-wrap: break-word;
}
 #map {
        width: 100%;
        height: 400px;
        margin-top: 0px !important;
        padding-top: 0px !important;
      
    }

.thumbnail {
	cursor: pointer;
}


.mydialog {
	 position: absolute;
  left: 100px;
  top: 500px;
}	   



.crosshair_cursor {
  cursor: crosshair !important;
  
}

.simple_cursor {
  cursor: '' !important;
}

</style>


