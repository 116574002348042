<template>
   <v-container>
     <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      > 
       <v-row v-for="asset in assets" :key="asset.id">
         <v-col
          lg="6"
          md="12"
          sm="12"
		  cols="12"
          >  
                <v-row class="ml-2">
                     <v-col
                     cols="12"
                     sm="12"
                     >
                        <v-text-field 
                         @input="form_changed"
                         v-model="asset.asset_title"
                         :rules="asset_title_rules"
                         counter="75"
                         hint="A title for this image"
                         label="Type a title for this image"
                         >
                     </v-text-field>
                     </v-col>
                     <v-col
                     cols="12"
                     sm="12"
                     >
                        <v-textarea
						:rows="description_field_rows"
                         label="Image description"
                         v-model="asset.asset_description"
                         hint="provide brief description about this image. e.g. something depicting damage of the business"
                         name="input-7-1"
                         >
                        </v-textarea>
                     </v-col>
				         <v-col
                     cols="12"
                     sm="12"
                     >
                         <v-combobox
						@change="clear_tag_search(asset.id)"
                         v-model="asset.asset_tags"
                         :items="asset.pluggy_tags"
                         :search-input.sync="tag_search"
                         hide-selected 
                         hint="Start typing to view already used tags derived from Pluggy platform"
                         label="Tags related to this image"
                         multiple
                         persistent-hint
                         small-chips
                         >
                             <template v-if="tag_search" v-slot:no-data>
                                 <v-list-item>
                                     <v-list-item-content>
                                         <v-list-item-title>
                                          No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                         </v-list-item-title>
                                     </v-list-item-content>
                                 </v-list-item>
                             </template>
                         </v-combobox>
                     </v-col>
                     <v-col
                     cols="12"
                     sm="12"
                     >
                           <v-file-input @change="file_changed(asset.id)" v-model="asset.image_file" :rules="imageRules"  prepend-icon="mdi-camera" chips show-size accept="image/*" label="Image"></v-file-input>
                     </v-col>
                </v-row>  
         </v-col>
         <v-col
         lg="6"
         md="12"
         sm="12"
		 cols="12"
         >
            <v-alert
            class="mt-4"
            color="#E7D8AC"
            dark
            icon="mdi-pin"
            dense
            >
                <div class="map_alert">
                 Pick a point on the map below so as to provide location for this image 
                </div>
            </v-alert>
            <v-container
            v-if="asset.asset_longitude && asset.asset_latitude"
            class="grey lighten-5  mt-0 pt-0"
            >
               <v-row
               align="center"
               no-gutters
               >
                  <v-col>
                       <v-text-field    
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="asset.asset_longitude"
                       label="Longitude"
                       outlined
                       readonly
                       >
                       </v-text-field>
                  </v-col>
                  <v-col>
                       <v-text-field    
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="asset.asset_latitude"
                       label="Latitude"
                       outlined
                       readonly
                       >
                       </v-text-field>
                  </v-col>
                  <v-col
				  lg="12"
                  md="4"
                  sm="12"
		          cols="12"
				  > 
                       <v-btn
                       @click="clear_asset_location(asset.id)"
                       class="mb-md-7 mx-xs-0 mx-md-4"
                       x-large
                       outlined
                       color="#695E4A"
                       >
                           <v-icon>mdi-eraser</v-icon>
                           Clear Location
                           </v-btn>
                  </v-col>
               </v-row>       
            </v-container>
            <div v-bind:id="'asset_map_'+asset.id" class="crosshair_cursor map">
            </div>
         </v-col>
      </v-row>    
     </v-form>   
      <v-row>
          <v-col
          cols="12"
          sm="12"
          >
             <v-btn
             class="ma-2"
             color="#695E4A"
             @click="add_image"
             >
                  <div style="color: #ffffff;">Add image</div>
                  <v-icon
                  right
                  dark
                  color="#ffffff"
                  >
                  mdi-plus
                  </v-icon>
             </v-btn>
             <v-btn v-if="assets_counter>0"
             color="#FFC425"
             class="ma-2 white--text"
             @click="remove_image"
             >
                <div style="color: #000000;">Remove image</div>
                <v-icon
                right
                dark
                color="#000000"
                >
                mdi-minus
                </v-icon>
             </v-btn>
          </v-col>
      </v-row>

	  <v-overlay light :value="overlay" z-index=1000 opacity="0.1">
          <v-progress-circular
          indeterminate
          color="primary"
          >
	      </v-progress-circular>
      </v-overlay>
	 
	  <v-row justify="center">
    	 <v-dialog
         v-model="dialog"
         persistent
         max-width="400"
         >
            <v-card>
               <v-card-title class="headline">
               Successful Story creation!
               </v-card-title>
               <v-card-text>Info concerning your local business damage has been successfully uploaded.</v-card-text>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="green darken-1"
                  text
                  @click="go_home"
                  >
		             <v-icon left>
                     mdi-home
                     </v-icon>
                  Home
                  </v-btn>
				  <!--
                  <v-btn
                  color="green darken-1"
                  text
                  @click="create_one_more_story"
                  >
				     <v-icon left>
                      mdi-plus
                     </v-icon>
				  Create one more story
                  </v-btn> -->
               </v-card-actions>
            </v-card>
         </v-dialog>
      </v-row>

    <v-snackbar
	  timeout="5000"
      v-model="snackbar"
      >
      The location of the image has been derived automatically
    </v-snackbar>

   </v-container>
</template>

<script>

import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL, MARKER_COLOR, MARKER_HTML_STYLE } from '../constants'; 
import axios from 'axios';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import store from '../store'
import { lavender } from 'color-name';
import { componentBus } from '../main';
import { mapGetters, mapActions } from 'vuex'
import jwt_decode from "jwt-decode";
import EXIF from 'exif-js';


export default {
props: ['clickedNext', 'currentStep'],
name: 'CitizenStoryThirdStep',

data: () => ({ 
snackbar: false,
exhibition_id: null,
exhibition_point_id: null,
overlay: false,
dialog: false,
assets_already_uploaded: 0,

image_file: null,
marker_icon: L.divIcon({
className: "",
iconAnchor: [0,24],
labelAnchor: [-6, 0],
popupAnchor: [0, -36],
html: `<span style="${MARKER_HTML_STYLE}" />`
}),
map: null,
marker: null,  
pluggy_tags: [],
tag_search: null,
asset_tags: null,
asset_title: null,
asset_description: null,
asset_longitude: null,
asset_latitude: null,
valid: true,
assets_counter: 0,
assets: [],

asset_title_rules: [
        v => (v && v.length > 0)  || 'Image title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',     
],

imageRules: [
        v => (!!v ) || 'Image is required',   
],







}),

mounted() {  

  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });
 //this.$emit('can-continue', {value: true});

 componentBus.$on('exhibition_id_from_second_step', (payload) => {
     console.log("third step elave to exhibition id"+ payload);
     this.exhibition_id= payload; 
     
   });

   componentBus.$on('exhibition_point_id_from_second_step', (payload) => {
     console.log("third step elave to exhibition point id"+ payload);
     this.exhibition_point_id= payload; 
     
   });
 
 

   componentBus.$on('finished', (flag) => {
     if (flag==true) 
     { 
		// console.log("story finished");
      //alert("now what?? ");
	  //this.$router.push('/');
	  //alert("finished");
	 

      this.overlay=true;
      this.store_all_assets_image_and_metadata();
      
     }
    
   });


   this.reset_validation();
   this.$emit('can-continue', {value: false});   // if assets_counter=0 he could continue
   //this.leaflet_dom_util=  L.DomUtil;

   /* this should be done every time the user wants to add image            */
   /*
   this.asset_map = L.map('asset_map', { zoomControl: true} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
   this.asset_latitude= e.latlng.lat;
   this.asset_longitude= e.latlng.lng;
   if(this.marker!= null)  this.asset_map.removeLayer(this.marker);
   this.marker=  L.marker([this.asset_latitude,this.asset_longitude], { icon: this.marker_icon }); 
   this.asset_map.addLayer( this.marker );   
   if(this.validate_form()) this.$emit('can-continue', {value: true});
   else this.$emit('can-continue', {value: false});

  });  
 L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
            })
            .addTo(this.asset_map); 
            */   
},

methods: {

...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'removeOidcUser',  
    ]),

update_exhibition_point() {

let update_exhibition_point_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+'/exhibition-points/'+this.exhibition_point_id;
let update_exhibition_point_url_encoded= encodeURI(update_exhibition_point_url);
var payload = {};
let exhibition_point_assets_list=[];
this.assets.forEach( (asset, index) => {
exhibition_point_assets_list.push(asset.asset_database_id);	
});
payload.assets= exhibition_point_assets_list;



return axios.put(update_exhibition_point_url_encoded, JSON.stringify( payload),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition point has been updated with assets");
        
              
          }
          else {
            console.log("problem with exhibition point assets update ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });




},

go_home() {
this.$router.push('/');

},
//not used..
create_one_more_story() {
this.$router.push('/');
this.$router.push('/citizen_story_creation');
//this.$router.push('/citizen_story_creation').catch(err => {});
//this.$router.go();

 //this.$router.replace({
  //path: '/citizen_story_creation',
 
//}).catch(err => {});
//this.$forceUpdate(); 
},

store_all_assets_image_and_metadata() {

//let mylist=[];
this.assets.forEach( (asset, index) => {
	this.store_asset_image_and_metadata(index);
	//mylist.push(this.store_asset_image_and_metadata(index))
});

//Promise.all(mylist)
  //.then( (results) => {
	
	//this.$router.push('/');
	
  //});

//console.log("assets: "+this.assets);
/* olososto
this.assets.forEach( (asset, index) => {
this.store_asset_image_and_metadata(index);
});
*/

},

store_asset_image_and_metadata(index) {
/*
let storeAssetMetadataPromise = new Promise((Resolve, Reject) => {
this.store_asset_metadata(index); 
Resolve(  ); 
Reject();  
});

storeAssetMetadataPromise.then(
  (value) => this.store_asset_image(index), (error) =>  {console.log("error: " + error); }
);
*/
Promise.all([this.store_asset_metadata(index)])
  .then( (results) => {

	  
      return this.store_asset_image(index);
	//console.log(results);
	

  });

},



store_asset_image(index) {

let upload_image_url=APIS_BASE_URL+"/assets/"+this.assets[index].asset_database_id+"/media";  
let upload_image_url_encoded= encodeURI(upload_image_url);
let formData = new FormData();
formData.append('file',this.assets[index].image_file);

return axios.post( upload_image_url_encoded,
          formData,
          {
           headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                    }
                    ).then( (image_upload_response) =>{
                    if (image_upload_response.status == "200" )
                     {
					 console.log("image successfully uploaded!");
					 this.assets_already_uploaded++;  
                     }
                    else
                     {
                     console.log('FAILURE with image upload upload');
                     }
                     })
                      .catch( () =>{
                      console.log('failure with image upload!!');
                     });

},




store_asset_metadata(index) {

let asset_url=APIS_BASE_URL+"/assets";
let asset_encoded= encodeURI(asset_url);
let payload = {};
payload["public"]= false;
payload["title"]= this.assets[index].asset_title;
payload["description"]= this.assets[index].asset_description;
payload["tags"]= this.assets[index].asset_tags;
payload["location"] =  {  geo: {  coordinates: [this.assets[index].asset_latitude, this.assets[index].asset_longitude], type: "Point", zoom: 14        }    };
payload["type"]="image";

return axios.post(asset_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("asset metadata have been stored");
              this.assets[index].asset_database_id=response.data.data.id;
              console.log("id of created asset: "+ this.assets[index].asset_database_id);
          }
          else {
            console.log("asset metadata creation problem");
          }                 
          })
          .catch(error => {
          console.log(error);
          });
},

form_changed() {
	let valid_locations=true;
	
	this.assets.forEach(function (asset, index) {
	  if (asset.asset_longitude == null ||  asset.asset_latitude == null) valid_locations=false;
	  });
	  
	if( this.validate_form() && valid_locations )
	   this.$emit('can-continue', {value: true});
    else this.$emit('can-continue', {value: false});

},

file_changed(asset_id) {


let _this=this;
if (this.assets[asset_id].image_file!=null)  
EXIF.getData(this.assets[asset_id].image_file, function() {
      
		let longitude_pre_processed= EXIF.getTag(this, "GPSLongitude");
		let lonDirection= EXIF.getTag(this, "GPSLongitudeRef");
		let latitude_pre_processed= EXIF.getTag(this, "GPSLatitude");
		let latDirection= EXIF.getTag(this, "GPSLatitudeRef");
		if(longitude_pre_processed || lonDirection || latitude_pre_processed || latDirection)
		{
		let lonDegree=  longitude_pre_processed[0];
		let lonMinute= longitude_pre_processed[1];
		let lonSecond= longitude_pre_processed[2];
		
		
		var dd = lonDegree + (lonMinute/60) + (lonSecond/3600);
        if (lonDirection == "S" || lonDirection == "W") {
        dd = dd * -1;
        }
        _this.assets[asset_id].asset_longitude= dd;

	
		let latDegree=  latitude_pre_processed[0];
		let latMinute= latitude_pre_processed[1];
		let latSecond= latitude_pre_processed[2];
		
		
		dd = latDegree + (latMinute/60) + (latSecond/3600);
        if (latDirection == "S" || latDirection == "W") {
        dd = dd * -1;
        }
        _this.assets[asset_id].asset_latitude= dd;     
		
		if(_this.assets[asset_id].marker!=null) _this.assets[asset_id].asset_map.removeLayer(_this.assets[asset_id].marker);  
	    //let icon=_this.citizen_story_pin_icon;
        //let m=  new _this.custom_marker([_this.assets[asset_id].asset_latitude,_this.assets[asset_id].asset_longitude], {icon: icon, asset_id: "id", asset_title: "asset_title" }).on('click', (e) => {
	//});
	    _this.assets[asset_id].marker=  L.marker([_this.assets[asset_id].asset_latitude,_this.assets[asset_id].asset_longitude], { icon: _this.marker_icon }); 
        _this.assets[asset_id].asset_map.addLayer( _this.assets[asset_id].marker );   

	   _this.snackbar=true;
	   _this.form_changed();
	}
    });
	else this.form_changed();
	

},

add_image() {

// counter equal to number of images

let asset_dict= {};
this.assets_counter++;
asset_dict.id=this.assets_counter-1;
asset_dict.image_file=null;
asset_dict.marker=null;
asset_dict.pluggy_tags= [];
asset_dict.tag_search= null;
asset_dict.asset_tags= [];
asset_dict.asset_title= null;
asset_dict.asset_description= null;
asset_dict.asset_longitude= null;
asset_dict.asset_latitude= null;
//asset_dict.asset_map=null;
this.assets.push(asset_dict);
setTimeout(() => { 
	
	this.assets[this.assets_counter-1].asset_map = L.map('asset_map_'+(this.assets_counter-1).toString(), { zoomControl: true} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
	let map_id= e.target._container.attributes.id.nodeValue.split("_")[2];
	this.assets[map_id].asset_longitude= e.latlng.lng;
	this.assets[map_id].asset_latitude= e.latlng.lat;

	if(this.assets[map_id].marker!= null)  this.assets[map_id].asset_map.removeLayer(this.assets[map_id].marker);
    this.assets[map_id].marker=  L.marker([e.latlng.lat,e.latlng.lng], { icon: this.marker_icon }); 
    this.assets[map_id].asset_map.addLayer( this.assets[map_id].marker );   
   
   let valid_locations=true;
	
	this.assets.forEach(function (asset, index) {
	  if (asset.asset_longitude == null ||  asset.asset_latitude == null) valid_locations=false;
	  });
	  
	if( this.validate_form() && valid_locations )
	   this.$emit('can-continue', {value: true});
    else this.$emit('can-continue', {value: false});
	
  

});  
 L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
           })
            .addTo(this.assets[this.assets_counter-1].asset_map); 

     }, 100);
     
let valid_locations=true;
	
	this.assets.forEach(function (asset, index) {
	  if (asset.asset_longitude == null ||  asset.asset_latitude == null) valid_locations=false;
	  });
	  
	if( this.validate_form() && valid_locations )
	   this.$emit('can-continue', {value: true});
    else this.$emit('can-continue', {value: false});



},

remove_image() {

let reduceNumberOfAssetsPromise = new Promise((Resolve, Reject) => {
   this.assets_counter--;
   this.assets[this.assets_counter].asset_map.remove();
   this.assets.splice(this.assets_counter, 1); 
   Resolve(  ); // when successful
   Reject();  // when error
});

reduceNumberOfAssetsPromise.then(
  (value) => this.form_changed(), (error) =>  {console.log("error: " + error); }
);

},

clear_tag_search(asset_id)

{ 
//if(this.validate_form() )  this.$emit('can-continue', {value: true});
      //else this.$emit('can-continue', {value: false});
this.tag_search=null;
},
  
clear_asset_location(asset_id)
{
this.assets[asset_id].asset_longitude=null;
this.assets[asset_id].asset_latitude=null;
if(this.assets[asset_id].marker!= null)  this.assets[asset_id].asset_map.removeLayer(this.assets[asset_id].marker);
this.assets[asset_id].marker=null;
this.$emit('can-continue', {value: false});


},
    
validate_form () {
return this.$refs.form.validate();
},
      
reset_form () {
this.$refs.form.reset();
},
      
reset_validation () {
this.$refs.form.resetValidation();
},

search_pluggy_tags(text) {
let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
axios.get(pluggy_tags_url_encoded
).then(response => {
   if (response.status == "200" || response.status == "201" ) {
	  console.log("pluggy_tags: "+response.data.data);
	  
	  this.assets.forEach( (item, index) => {
      item.pluggy_tags=response.data.data;
  
});
	 // it is the correct  this.pluggy_tags=response.data.data;
   }
   else {
         console.log("problem with exhibition point creation ");
   }                 
})
.catch(error => {
console.log(error);
});

},
  
},

watch: {

assets_already_uploaded (val) {

if (this.assets.length==val) 
{



  Promise.all([this.update_exhibition_point()])
  .then( (results) => {

	  
    this.overlay=false;
	this.dialog=true;
	//console.log(results);
	

  });


	
	//this.$router.push('/');
	
}

},
/*
 assets: {
            handler (val) {
               // console.log("Person with ID:" + newValue.id + " modified")
			   // console.log("New age: " + newValue.age)
			   alert("aek");
            },
			deep: true,
			
        },

*/
/*
tag_search (val) {

if( val!="" )
   this.search_pluggy_tags(val);
else this.pluggy_tags=[];
},
*/
tag_search (val) {

if( val!="" )
   this.search_pluggy_tags(val);
else this.assets.forEach( (item, index) => {
  item.pluggy_tags=[];
  
});



},





currentStep: function (val) {

let valid_locations=true;
this.assets.forEach(function (asset, index) {
	if (asset.asset_longitude == null ||  asset.asset_latitude == null) valid_locations=false;
	});
	  
if( this.validate_form() && valid_locations )
	this.$emit('can-continue', {value: true});
else this.$emit('can-continue', {value: false});
},

},

computed: {
      description_field_rows () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 2
          case 'sm': return 2
          case 'md': return 4
          case 'lg': return 6
          case 'xl': return 8
        }
      },
    },
  
  destroyed() {
    componentBus.$off('exhibition_id_from_second_step');
    componentBus.$off('exhibition_point_id_from_second_step');
    componentBus.$off('finished');
  }


};

</script>


<style>


.map {
width: 100%;
height: 330px;
}

.map_alert {
  color:rgba(0, 0, 0, 0.87);;
}

.crosshair_cursor {
  cursor: crosshair !important;
}

</style>