<template>
   <v-container>
       <v-form
       ref="form"
       v-model="valid"
       lazy-validation
       > 
	        <v-row>
             <v-col  cols="6">
                <v-textarea
                 label="Describe the damage of the monument"
                 v-model="monument_damage_description"
                 hint="provide brief description about the deterioration of the CH site"
                 :rules="monument_damage_description_rules"
                 name="input-7-1"
                 >
                 </v-textarea>
             </v-col>
             <v-col
             cols="6"
             >
                  <v-textarea
                  label="Describe the risk for the monument"
                  v-model="monument_risk_description"
                  hint="provide brief description about the monument risk - risk assessment"
                  :rules="monument_risk_description_rules"
                  name="input-7-1"
                  >
                  </v-textarea>
             </v-col>
			       <v-col
             cols="6"
             >
			      <v-textarea 
				         height="190" 
                 label="Describe the risk for people of the surrounding area"
                 v-model="people_risk_description"
                 hint="provide brief description about the risk for people of surrounding area "
                 :rules="people_risk_description_rules"
                 name="input-7-1"
                 >
                 </v-textarea>
             </v-col>
             <v-col
             cols="6"
             >
                 <v-row no-gutters>
                     <v-col  cols="12">
			                    <v-select
                           v-model="priority"
                           :items="priority_items"
                           label="Select priority of the issue"
                           :rules="priority_rules"
                           > 
			    	              </v-select> 
                      </v-col> 
                      <v-col cols="12">
                            <v-select
                             v-model="criticality"
                             :items="criticality_items"
                             label="Select criticality of the issue"
                             :rules="criticality_rules"
                             > 
			    	                </v-select> 
                      </v-col>
                      <v-col cols="12">
                           <v-combobox
						   @change="clear_tag_search"
                           v-model="exhibition_point_tags"
                           :items="pluggy_tags"
                           :search-input.sync="tag_search"
                           hide-selected 
                           hint="Start typing to view already used tags derived from Pluggy platform"
                           label="Add some tags related to this story"
                           multiple
                           persistent-hint
                           small-chips
                           >
                               <template v-if="tag_search" v-slot:no-data>
                                   <v-list-item>
                                       <v-list-item-content>
                                           <v-list-item-title>
                                            No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                           </v-list-item-title>
                                       </v-list-item-content>
                                   </v-list-item>
                               </template>
                           </v-combobox>
                      </v-col>
                   

                 </v-row>
             </v-col>
        </v-row>   
	   </v-form> 
   </v-container>
</template>

<script>
import {   APIS_BASE_URL } from '../constants';
import axios from 'axios';
import store from '../store';
import { componentBus } from '../main';
import { mapGetters, mapActions } from 'vuex'
import jwt_decode from "jwt-decode";

export default {
  name: 'CitizenStorySecondStep',
  props: ['clickedNext', 'currentStep'],

  data: () => ({
  pluggy_tags: [],
  tag_search: null,
  exhibition_point_tags: null,
  exhibition_id: null,
  exhibition_point_id: null,
  priority: null,
  criticality: null,
  valid: true, 
  priority_items: [ { text: 'Urgent', value: 5}, {text: 'High', value: 4},{text: 'Medium', value: 3} ,{text: 'Low', value: 2}, {text: 'Very low', value: 1}],
  criticality_items: [ { text: "Very high", value: 5},{ text: "High", value: 4},{ text: "Medium", value: 3},{ text: "Low", value: 2}, { text: "Very low", value: 1 }],
  monument_damage_description: null,
  monument_risk_description: null,
  people_risk_description: null,

  monument_damage_description_rules: [
        v => (v && v.length > 0)  || 'Monument damage description is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
	],
	
	monument_risk_description_rules: [
        v => (v && v.length > 0)  || 'Risk assessment is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
	],
	
	people_risk_description_rules: [    
        v => (v && v.length > 0)  || 'Desciption regarding people risk in surrounding area is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',          
        
  ],
  priority_rules: [
        v => (v && v > 0)  || 'Priority of the issue is required',     
  ],
  criticality_rules: [
        v => (v && v > 0)  || 'Criticality of the issue is required', 
  ]



  }),
  mounted() {

  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });



  componentBus.$on('completed_step', (payload) => {     
     if (payload.name=="second") 
     { 
       console.log("tora sose plirofories gia second step");       
       this.store_exhibition_point_main_info();
     }
     //let str = JSON.stringify(payload);
    //console.log("molis elava gia completed step"+ str);
   });

    componentBus.$on('exhibition_id', (payload) => {
     console.log("molis elava to exhibition id"+ payload);
     this.exhibition_id= payload; 
     
   });
   this.reset_validation();
   this.$emit('can-continue', {value: false});
 
  },
  methods: {

      ...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'removeOidcUser',  
    ]),

	  clear_tag_search()
      {
	   this.tag_search=null;
	  },

	  search_pluggy_tags(text) {
	  let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
      let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
     

      axios.get(pluggy_tags_url_encoded
          ).then(response => {
          if (response.status == "200" || response.status == "201" ) {
            
             
			  console.log("pluggy_tags: "+response.data.data);
			  this.pluggy_tags=response.data.data;
              
          }
          else {
            console.log("problem with exhibition point creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	  },



      store_exhibition_point_content() {
      //let upload_content_url=APIS_BASE_URL+"/exhibition-points/"+this.exhibition_point_id+"/content";  
      let upload_content_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+"/exhibition-points/"+this.exhibition_point_id+"/content";  
      let upload_content_url_encoded= encodeURI(upload_content_url);
      let payload= {};
      payload["monument_damage_description"]= this.monument_damage_description;
      payload["monument_risk_description"]= this.monument_risk_description;
      payload["people_risk_description"]= this.people_risk_description;
      payload["priority"]= this.priority;
      payload["criticality"]= this.criticality;
      let payload_string= JSON.stringify(payload);
      let blob = new Blob([payload_string], { type: 'application/json' });
      let file = new File([blob], "content.json", {type: "application/json"});

      let formData = new FormData();
      //formData.append('file',this.image_file);
      formData.append('file',file);

                                axios.post( upload_content_url_encoded,
                                 formData,
                                 {
                                 headers: { 'Content-Type': 'multipart/form-data','authorization': "Bearer "+ store.state.oidcStore.access_token}
                                 }
                          
                                ).then( (content_upload_response) =>{

                                if (content_upload_response.status == "200" )
                                   {
                                    console.log("content successfully uploaded!");
                                   }
                               
                                else
                                 {
                                  console.log('FAILURE with exhibition point content upload');
                                 }
                                  })
                                 .catch( () =>{
                                   console.log('failure with exhibition point content upload!!');
                                   });


      
     },
     validate_form () {
       return this.$refs.form.validate();
      },
      reset_form () {
        this.$refs.form.reset();
      },
      reset_validation () {
        this.$refs.form.resetValidation();
      },
      store_exhibition_point_main_info(){
      let exhibition_point_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id+"/exhibition-points";
      let exhibition_point_url_encoded= encodeURI(exhibition_point_url);
      var payload = {};
      payload["public"]= false;
	  payload["title"]= "CH site deterioration";
	  payload["tags"]= this.exhibition_point_tags;

      axios.post(exhibition_point_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition point has been created");
              this.exhibition_point_id=response.data.data.id;
			  console.log("id of created exhibition point: "+this.exhibition_point_id);
			  componentBus.$emit('exhibition_id_from_second_step', this.exhibition_id);
			  componentBus.$emit('exhibition_point_id_from_second_step', this.exhibition_point_id);
			  
              this.store_exhibition_point_content();
          }
          else {
            console.log("problem with exhibition point creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });


    },
   },
   watch: {
    priority: function (val) {
     
      if(this.validate_form()) this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
     
    },
    criticality: function (val) {
      if(this.validate_form()) this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
      
    },
    monument_damage_description: function (val) {
      if(this.validate_form() )  this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
    },
    monument_risk_description: function (val) {
      if(this.validate_form() )  this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
    },
    people_risk_description: function (val) {
      if(this.validate_form() )  this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
    },
    currentStep: function (val) {
      
       if(this.validate_form()  )  this.$emit('can-continue', {value: true});
	},
	
    tag_search (val) {
	   if( val!="" )
	   this.search_pluggy_tags(val);
	   else this.pluggy_tags=[];
      },

   },

   destroyed() {
     componentBus.$off('completed_step');
     componentBus.$off('exhibition_id');
   }
};
</script>
