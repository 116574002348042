<template>
   <v-container>
    
      
      <v-row>
         <v-col
         lg="6"
         md="12"
         sm="12"
		 cols="12"
         >    
          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               > 
            <v-row class="ml-2">
              
             
                  <v-col
                  cols="12"
                  sm="12"
                  >
                     <v-text-field 
                      v-model="exhibition_title"
                      :rules="exhibition_title_rules"
                      counter="75"
                      hint="type of business, place, etc."
                      label="Business Owners Story title"
                      >
                     </v-text-field>
                  </v-col>
                  <v-col
                  cols="12"
                  sm="12"
                  >
                     <v-textarea
					 :rows="description_field_rows"
                     label="Type a description for this Business Owners Hyperion Story"
                     v-model="exhibition_description"
                     hint="provide brief description about your business, place, short damage description, urgency, etc."
                     :rules="exhibition_description_rules"
                     name="input-7-1"
                     >
					      <template  v-if="$vuetify.breakpoint.mobile" v-slot:label>
                          Business Story description
                          </template>
                     </v-textarea>
                  </v-col>
				  <v-col>
					  <v-combobox
					  @change="clear_tag_search"
                      v-model="exhibition_tags"
                      :items="pluggy_tags"
                      :search-input.sync="tag_search"
                      hide-selected 
                      hint="Start typing to view already used tags derived from Pluggy platform"
                      label="Add some tags related to the business damage"
                      multiple
                      persistent-hint
                      small-chips
                      >
                        <template v-if="tag_search" v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    No results from Pluggy matching "<strong>{{ tag_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
						<template v-if="$vuetify.breakpoint.mobile" v-slot:label>
                        Monument damage tags
                        </template>
                       </v-combobox>
				  </v-col>
                
            </v-row>   </v-form> 
         </v-col>
         <v-col
         lg="6"
         md="12"
         sm="12"
		 cols="12"
         >
           <v-alert
           
           class="mt-4"
           color="#E7D8AC"
           dark
           icon="mdi-pin"
           dense
           >
           <div id="alert">
           Pick a point on the map below so as to provide location of Business Owners Hyperion Story </div>
           </v-alert>

           <v-container
            v-if="exhibition_longitude && exhibition_latitude"
            class="grey lighten-5  mt-0 pt-0"
            >
              <v-row
              align="center"
              no-gutters
              >
                 <v-col
				 lg="6"
                 md="4"
                 sm="6"
		         cols="6"
				 >
                  
                       <v-text-field    
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="exhibition_longitude"
                       label="Longitude"
                       outlined
                       readonly
                       ></v-text-field>
                  
                 </v-col>
                 <v-col
				 lg="6"
                 md="4"
                 sm="6"
		         cols="6"
				 >
                   
                       <v-text-field    
                       class="mx-xs-0 mx-md-4 my-0 py-0"
                       v-model="exhibition_latitude"
                       label="Latitude"
                       outlined
                       readonly
                       >
                       </v-text-field>
                  
                 </v-col>
                 <v-col
				 lg="12"
                 md="4"
                 sm="12"
		         cols="12"
				 > 
                   
                       <v-btn
                       @click="clear_exhibition_location"
                       class="mb-md-7 mx-xs-0 mx-md-4"
                       x-large
                       outlined
                       color="#695E4A"
                       >
                          <v-icon>mdi-eraser</v-icon>
                           Clear Location
                       </v-btn>
                
                 </v-col>
              </v-row>     
          
           </v-container>




           <div id="exhibition_map" class="crosshair_cursor"></div>
         </v-col>


                  


                
      </v-row>
   </v-container>
</template>

<script>
import jwt_decode from "jwt-decode";
import {  ATTRIBUTION, MAP_URL, APIS_BASE_URL, MARKER_COLOR, MARKER_HTML_STYLE } from '../constants'; 
import axios from 'axios';
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import store from '../store'
import { lavender } from 'color-name';
import { componentBus } from '../main';
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['clickedNext', 'currentStep'],
  name: 'BusinessOwnersStoryFirstStep',
  
  methods: {

	...mapActions('oidcStore',[
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'removeOidcUser',  
    ]),

	clear_tag_search()
    {
	this.tag_search=null;
	},

	search_pluggy_tags(text) {
	  let pluggy_tags_url=APIS_BASE_URL+"/hinting/tags?q="+text;
      let pluggy_tags_url_encoded= encodeURI(pluggy_tags_url);
     

      axios.get(pluggy_tags_url_encoded
          ).then(response => {
          if (response.status == "200" || response.status == "201" ) {
            
             
			  console.log("pluggy_tags: "+response.data.data);
			  this.pluggy_tags=response.data.data;
              
          }
          else {
            console.log("problem with exhibition point creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });

	},

    create_exhibition()
    {
    let create_exhibition_url=APIS_BASE_URL+"/exhibitions/";
    let create_exhibition_url_encoded= encodeURI(create_exhibition_url);
    var payload = {};

    axios.post(create_exhibition_url_encoded,JSON.stringify( payload ),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition has been created");
              this.exhibition_id=response.data.data.id;
              console.log("id of created exhibition: "+this.exhibition_id);
          }
          else {
            console.log("problem with exhibition creation ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });
    },

    store_exhibition_main_info(){
    let store_exhibition_main_info_url=APIS_BASE_URL+"/exhibitions/"+this.exhibition_id;
    let store_exhibition_main_info_url_encoded= encodeURI(store_exhibition_main_info_url);
    var payload = {};
    //this.exhibition_title   this.exhibition_description
    payload.title= this.exhibition_title;
    payload.description= this.exhibition_description;
    let location_dict= {  geo: {  coordinates: [this.exhibition_latitude, this.exhibition_longitude], type: "Point", zoom: 14        }             };
    payload.location= location_dict;
    payload.type= "hyperion_business_owners_story";
    payload.saved=true;
    payload.public=false;
    this.exhibition_tags.push("Hyperion Story");
    this.exhibition_tags.push(this.the_date_today);
	  payload.tags= this.exhibition_tags;



    axios.put(store_exhibition_main_info_url_encoded, JSON.stringify(  payload),
          {
          headers: { 'Content-Type' : 'application/json', 'authorization': "Bearer "+ store.state.oidcStore.access_token}
          }).then(response => {
          if (response.status == "200" || response.status == "201" ) {
              console.log("exhibition main info has been stored");
              console.log("request success: "+ response.data.success);
              componentBus.$emit('exhibition_id', this.exhibition_id);
          }
          else {
            console.log("problem with exhibition main info storage ");
          }                 
          })
          .catch(error => {
          console.log(error);
          });


    },

    clear_exhibition_location()
    {
      this.exhibition_latitude= null;
      this.exhibition_longitude= null;
      if(this.marker!= null)  this.exhibition_map.removeLayer(this.marker);
      this.marker=null;
      this.$emit('can-continue', {value: false});
    },
    validate_form () {
       return this.$refs.form.validate();
      },
      reset_form () {
        this.$refs.form.reset();
      },
      reset_validation () {
        this.$refs.form.resetValidation();
      },
      pad2(n) {
        return ( n < 10 ? '0' : '' ) + n;
      }
  },

  data: () => ({
   exhibition_tags: [],
   pluggy_tags: [],
   tag_search: null,
   exhibition_id: null,
   marker_icon: L.divIcon({
                  className: "",
                  iconAnchor: [0,24],
                  labelAnchor: [-6, 0],
                  popupAnchor: [0, -36],
                  html: `<span style="${MARKER_HTML_STYLE}" />`
                  }),
    valid: true,              
    marker: null,
    exhibition_latitude: null,
    exhibition_longitude: null,
    exhibition_map: null,
    exhibition_title: null,
    exhibition_title_rules: [
        v => (v && v.length > 0)  || 'Story title is required',
        v => (v && v.length <= 75)  || 'Max 75 characters',
        
    ],
    exhibition_description: null,
    exhibition_description_rules: [
        v => (v && v.length > 0)  || 'Story description is required',
        v => (v && v.length <= 2000)  || 'Max 2000 characters',
        
    ],



  }),
  mounted() {

  axios.interceptors.request.use( (config) => {
  const token = store.state.oidcStore.access_token;
  let token_string = token.toString()
  let exp=jwt_decode(token_string).exp;
  var expiration_date = new Date( parseInt(exp) * 1000 );
  var current_date = new Date();
  if( expiration_date <= current_date){
	 this.removeOidcUser();
     this.authenticateOidc();
  }
  return config;
  }, function (error) {
    // Do something with request error
  return Promise.reject(error);
  });



   this.create_exhibition();
   componentBus.$on('completed_step', (payload) => {
     if (payload.name=="first") 
     { 
       console.log("tora sose plirofories");       
       this.store_exhibition_main_info();
       //componentBus.$emit('exhibition_id', this.exhibition_id);
     }
     //let str = JSON.stringify(payload);
    //console.log("molis elava gia completed step"+ str);
   });





    console.log("here again");
   let str = JSON.stringify(this.currentStep);
   console.log("prop: "+str);
   this.reset_validation();
   this.$emit('can-continue', {value: false});
   //if(this.validate_form()) this.$emit('can-continue', {value: true});
   //else this.$emit('can-continue', {value: false});
   this.leaflet_dom_util=  L.DomUtil;
   this.exhibition_map = L.map('exhibition_map', { zoomControl: true} ).setView([37.9838, 23.7275], 5).on('click', (e) => {
   this.exhibition_latitude= e.latlng.lat;
   this.exhibition_longitude= e.latlng.lng;
   
   if(this.marker!= null)  this.exhibition_map.removeLayer(this.marker);
   this.marker=  L.marker([this.exhibition_latitude,this.exhibition_longitude], { icon: this.marker_icon }); 
   this.exhibition_map.addLayer( this.marker );   
   if(this.validate_form()) this.$emit('can-continue', {value: true});
   else this.$emit('can-continue', {value: false});

                  //this.map.setView([response.data.latestLocation.location.coordinates[1],response.data.latestLocation.location.coordinates[0]], 18);

   
  });
 L.tileLayer(MAP_URL, {
                attribution: ATTRIBUTION,
                subdomains: ['a', 'b', 'c']
            })
            .addTo(this.exhibition_map);    
  },
   watch: {
    exhibition_longitude: function (val) {
      //if(val==null) alert("problem with longitude");
      if(this.validate_form() && this.exhibition_latitude && this.exhibition_longitude) this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
      //console.log(this.validate_form());
    },
    exhibition_latitude: function (val) {
      if(this.validate_form() && this.exhibition_latitude && this.exhibition_longitude) this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
      //if(val==null) alert("problem with latitude");
    },
    exhibition_title: function (val) {
      if(this.validate_form() && this.exhibition_latitude && this.exhibition_longitude )  this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
    },
    exhibition_description: function (val) {
      if(this.validate_form() && this.exhibition_latitude && this.exhibition_longitude )  this.$emit('can-continue', {value: true});
      else this.$emit('can-continue', {value: false});
    },
    currentStep: function (val) {
      //alert("allakse to current step");

      console.log("allakse to current step"+ val);
      //alert("allakse to current step");
       if(this.validate_form() && this.exhibition_latitude && this.exhibition_longitude )  this.$emit('can-continue', {value: true});
    },
     //clickedNext(val) {
          //      console.log(val);
            //    if(val === true) {
            //        alert("nikos");
          //      }
          //  }
	tag_search (val) {
	   if( val!="" )
	   this.search_pluggy_tags(val);
	   else this.pluggy_tags=[];
    },

   },
    computed: {
      description_field_rows () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 2
          case 'sm': return 2
          case 'md': return 4
          case 'lg': return 6
          case 'xl': return 8
        }
      },
      
      the_date_today() {
      let date = new Date();
      let month = this.pad2(date.getMonth()+1);
      let day= this.pad2(date.getDate());
      let year= date.getFullYear();
      let formattedDate = day+"-"+month+"-"+year;
      return formattedDate;
      }
    },

    destroyed() {
      componentBus.$off('completed_step');
    }
};
</script>


<style>


#exhibition_map {
        width: 100%;
        height: 400px;
        
       
      
    }

#alert {
  color:rgba(0, 0, 0, 0.87);;
}


.crosshair_cursor {
  cursor: crosshair !important;
}

</style>