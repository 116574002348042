import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import store from './store';
import router from './router';
import axios from 'axios';

import { mapGetters, mapActions } from 'vuex'



//import jwt_decode from "jwt-decode";

export const componentBus = new Vue();



Vue.config.productionTip = false;



//const token = store.state.oidcStore.access_token;
//console.log("wonderful: "+token);
/*
if (token) {
  
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${token}`
    
  }, error => {
    return Promise.reject(error)
  })

   
  }
   
 */

  //Vue.prototype.$http.defaults.headers.common['Authorization'] = token

 
//Vue.use(jwt_decode);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
